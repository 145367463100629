<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/FOLLETO_EKT.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/INFOGRAFIA_EKT.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
    <!--<a class="video" (click)="mostrarVideo()"> Video</a> -->
    <a class="faqs" href="../../../../../../assets/docs/home/PREGUNTAS_FRECUENTES_EKT.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">¡Tu lealtad te recompensa!</h1>
<p class="textoverdeTor"><strong>¿Qué es?</strong></p>
<p>Un Beneficio con el cual podrás <strong>comprar productos</strong>  para el <strong>hogar, telefonía, tecnología</strong> y  <strong>movilidad</strong> de contado o a pagos dentro de tiendas   <strong>Elektra </strong></p>
<p class="textoverdeTor"><strong>¿Para quién es?</strong></p>
<p>Todos los Colaboradores de GS <sup>1</sup></p>
<p class="textoverdeTor"><strong>¿Qué beneficios exclusivos te ofrece?</strong></p>
<p>La posibilidad de adquirir <strong>miles de productos</strong>  dentro de tiendas <strong>Elektra y Salinas & Rocha</strong> , con <strong>descuento</strong>  de acuerdo a tu <strong>antigüedad</strong> a un <strong>precio</strong> exclusivo para ti
</p>

<p class="textoverdeTor"><strong>Características</strong></p>
<ul class="contenido">
    <li><strong>Monto máximo de compra<sup>2</sup> </strong> es el equivalente hasta 3 veces la cantidad de tu sueldo neto mensual con un límite de  <strong>$30,000</strong> como Colaborador y <strong>$90,000</strong> como Director <strong>(solo en compras de contado)</strong></li>
    <li>El <strong>precio especial es</strong>  variable con relación a tu antigüedad, es decir <strong>a mayor antigüedad más descuento</strong> </li>
    <li>Aplica para compras en tiendas físicas <strong> Elektra y Salinas & Rocha</strong></li>
    <li>Productos Apple, Telcel<sup>3</sup> y consolas de videojuegos podrán adquirirse de contado y a pagos, solo aplican los descuentos de tienda </li>

    <p><strong>Para compras de Contado:</strong></p>
    <li>Podrás utilizar todo tu <strong>monto máximo de compra</strong>, sin embargo, si lo usas completo no tendrás saldo<sup>4</sup> para comprar a pagos </li>
 
    <p><strong>Para compras a pagos:</strong> </p>
    <li>Podrás comprar <sup>5</sup>, máximo el equivalente a un mes de tu salario topado a <strong>30 mil pesos</strong>. Si lo usas todo y no rebasas el <strong>monto máximo de compra</strong> tendrás disponible el remanente para compras de contado  </li>
    <li>Plazos de <strong>4 a 12 meses</strong>, pago con cargo a tu nómina  </li>
    <li>Tasa anual del <strong>24%</strong></li>


</ul>

<p class="contactanos text-center">Revisa tu monto disponible en Portal Recursos Humanos > Administración RH > Venta Empleados</p>


    <div class="subindices">
        <p><sup><strong>1</strong></sup> Adamtium, comisionistas y trabajadores por honorarios solo aplican compras de contado </p>
        <p><sup><strong>2</strong></sup> El límite puede variar con respecto a tu percepción salarial </p>
        <p><sup><strong>3</strong></sup> No participan Planes tarifarios y descuentos en Tiempo Aire        </p>
        <p><sup><strong>4</strong></sup> El límite disponible volverá a ser vigente, una vez concluido un año de tu primera compra de contado  </p>
        <p><sup><strong>5</strong></sup> Tu préstamo disponible volverá a ser vigente al concluir el pago total de tu compra y esta topado a 1 mes de sueldo o 30 mil pesos</p>
    </div>

<p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
<p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> <strong>55 7099 1199</strong> <br> Opción <strong>5</strong></p>
<div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
    href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div>
    <hr class="prodBottom">
    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
          </div>
          <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
          </div>
    </div>



<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }

    function redireccionAlSitio() {
        try {
            var surl = self;
            if (validateURL(surl))
            top.location.href = surl;
            else {
            throw new InvalidURLException();
            }
        } catch (e) {
            if (e instanceof InvalidURLException)
            alert(e.message);
        }
    }

    function InvalidURLException() {
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";
        this.toString = function () {
            return this.message
        };
    }

    function validateURL(surl) {
        var url = parseURL(surl);
        var urlHostname = url.hostname.trim();

        if (urlHostname == '') {
            return true;
        } else {
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {
            return true;
            } else {
            return false;
            }
        }
    }

    function parseURL(url) {
    var a = document.createElement('a');
    a.href = url;
    return {
        source: url,
        protocol: a.protocol.replace(':', ''),
        hostname: a.hostname,
        host: a.host,
        port: a.port,
        query: a.search,
        params: (function () {
            var ret = {},
                seg = a.search.replace(/^\?/, '').split('&'),
                len = seg.length, i = 0, s;
            for (; i < len; i++) {
                if (!seg[i]) { continue; }
                s = seg[i].split('=');
                ret[s[0]] = s[1];
            }
            return ret;
        })(),
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
        hash: a.hash.replace('#', ''),
        path: a.pathname.replace(/^([^\/])/, '/$1'),
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
        segments: a.pathname.replace(/^\//, '').split('/')
        };
    }
</script>
