import { Component, ViewChild, OnInit, Host, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BodyComponent } from 'src/app/shared';
import { ApiAdelantoService } from '../../../../core/services/credito/api.adelanto/api.adelanto';
import { AdelantoEfectivoModel } from '../../../../core/models/credito/api.adelanto/adelanto.model';
import { Intentos } from 'src/app/core/services/utileria/utileria.enum';
import { DialogReintentarComponent } from '../../../../shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { MatDialog } from '@angular/material/dialog';
import { ValidacionErrors } from '../../../../core/services/utileria/validacion.error';
import { PrestamoPersonalModel } from 'src/app/core/services/credito/api.prestamos/PrestamoPersonal.model';

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html'
})
export class ResumenComponent implements OnInit, OnDestroy {

  @ViewChild(BodyComponent) body: BodyComponent;
  @Output() salir = new EventEmitter<boolean>();
  public producto: number;
  nombreEmpleado: string;
  folioCredito: string;
  adelanto: AdelantoEfectivoModel;
  intentos: number;
  prestamo : PrestamoPersonalModel;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private validacionError: ValidacionErrors,
    private apiAdelanto: ApiAdelantoService,
    ) {
  }

  ngOnInit(): void {
    this.intentos = 1;
    if (window.sessionStorage) {
      this.producto = (sessionStorage.getItem('producto_id') == null
        || parseInt(sessionStorage.getItem('producto_id'), 10) === 3) ?
        1 : parseInt(sessionStorage.getItem('producto_id'), 10);
    } else {
      this.producto = 1;
    }
    this.ocultarBody('none', 'body-d', 'head-d');
    this.ocultarTitulo('none');
    if (window.sessionStorage) {
      if (sessionStorage.getItem('nombrePila') !== null) {
        this.nombreEmpleado = sessionStorage.getItem('nombrePila') !== undefined ?
          sessionStorage.getItem('nombrePila').split(' ')[0] : '';
        this.folioCredito = sessionStorage.getItem('folioCredito') !== undefined ?
          sessionStorage.getItem('folioCredito') : '';
      }

    }
    this.obtenerDatosCredito();
  }

  ocultarBody(displayType: string, bodyClass: string, headClass: string): void {
      const aside = document.getElementById('myaside');
      const body = document.getElementById('body');
      const head = document.getElementById('head');
      aside.style.display = displayType;
      if (body !== null) {
        body.removeAttribute('id');
        body.setAttribute('id', bodyClass);
      }
      if (head !== null) {
        head.removeAttribute('id');
        head.setAttribute('id', headClass);
      }
  }

  ocultarTitulo(styleTitulo: string): void {
    window.onload = () => {
      const titulo = document.getElementById('tituloProducto');
      titulo.style.display = styleTitulo;
    };
  }

  ngOnDestroy(): void {
    this.ocultarBody('block', 'body', 'head');
    this.ocultarTitulo('block');
  }

  obtenerDatosCredito() {

    switch (this.producto) {
      case 1 : {
        this.apiAdelanto.getAdelantoPorSolicitud(this.folioCredito).subscribe(adelanto => {
          this.adelanto = adelanto.resultado as AdelantoEfectivoModel;
          this.adelanto.estatus = this.adelanto.estatus === 'Validacion' ? 'Validación' : this.adelanto.estatus;
          this.intentos = 1;
        }, error => {
          console.log('Error al consultar adelanto: ' + JSON.stringify(error));
          this.manejoErrorConexion(error);
        });
      }break;
      case 2: {
        this.apiAdelanto.getAdelantoPorSolicitudPrestamo(this.folioCredito, this.producto).subscribe(prestamo => {
          this.prestamo = prestamo.resultado as PrestamoPersonalModel;
          this.prestamo.estatusDescripcion = this.prestamo.estatusDescripcion === 'Validacion' ? 'Validación' : this.prestamo.estatusDescripcion;
          this.intentos = 1;
        }, error => {
          console.log('Error al consultar préstamo: ' + JSON.stringify(error));
          this.manejoErrorConexion(error);
        });
      }break;
      default: console.log('opcion no valida'); 
    }

  }

  manejoErrorConexion(error) {
    if (error.status === 400 || (error.status > 402 && error.status < 500)) {
      this.dialog.closeAll();
      this.openDialogReintentar(this.validacionError.getError(error), 'OK', false);
    } else if (error.status === 500 && this.intentos < Intentos.MAX_INTENTOS) {
      this.intentos++;
      this.dialog.closeAll();
      this.openDialogReintentar('Ocurrio un problema de conexión. Intentalo nuevamente', 'Reintentar', true);
    } else if (error.status === 500 && this.intentos >= Intentos.MAX_INTENTOS) {
      this.dialog.closeAll();
      this.openDialogReintentar('Ocurrio un problema de conexión. Por favor inténtalo más tarde', 'OK', false);
      this.router.navigate(['/home'], { skipLocationChange: false });
    }
  }

  openDialogReintentar(msg: string, boton: string, reintento: boolean): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      if (reintento) {
        this.obtenerDatosCredito();
      }
    });
  }


  routeFunction(ruta: String) {
    if (this.router.url === '/home') {
    sessionStorage.removeItem('producto_id');
    sessionStorage.removeItem('folioCredito');
      this.salir.emit(false);
    } else {
      this.router.navigate([ruta], { skipLocationChange: false });
    }
  }

}
