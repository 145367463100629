<aside>
    <div #aside1 id="aside1">
        <div #bkgid id="bkgid">
        <span></span>
        </div>
        <div class="track">
        <!------------- Inicia Progress-bar dinámico con estilos hasta el 10 ------------->
        <ul class="step d-flex flex-nowrap" id="tracking">
            <li class="step-item tracking-dis" [routerLink]="['/'+item]" [routerLinkActive]="['active']" tabindex="-1"
            *ngFor="let item of listaProgreso; let indice=index">
            <a tabindex="-1" class="paso{{indice+1}}">&nbsp;</a>
            </li>
        </ul>
        <!------------- Finaliza Progress-bar dinámico con estilos hasta el 10 ------------->
        </div>
    </div>
    <div #aside2 id="aside2" class="second_aside">
        <img alt="imagenPerfil" src="{{ urlImagenPerfil }}" (error)="cargarImagenPerfilPorDefecto()">
        <p class="nombreSocio"><strong> {{ nombreEmpleado | titlecase }}</strong></p>
        <p class="puestoSocio">{{ puesto | titlecase }}</p>
        <div class="detalle">
        <p> {{ numEmpleado }} </p>
        <p> {{ correoEmpleado }} </p>
        <p> {{ telefono }} </p>
        <p> {{ antiguedad }} </p>
        <p> {{ areaEmpleado | titlecase }} </p>
        <p> {{ direccion }} </p>
        </div>
    </div>
</aside>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>