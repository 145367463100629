import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ConversorPipe } from './pipes/formatoMiles.pipe';
import { MilesPipe } from './pipes/formatoMaxMin.pipe';
import { ListErrorsComponent } from './list-errors.component';
import { SpinnerComponent } from './components/spinnner/spinner.component';
import { AgmCoreModule } from '@agm/core';
import { FechaPipe } from './pipes/fechaPipe';
import { FechaPipeResumen } from './pipes/fechaPipeResumen';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyCsK3vhIFmV5l6JpN9WE8BYPdDaXDaKNrw'
    })
  ],
  declarations: [
    ListErrorsComponent,
    ConversorPipe,
    MilesPipe,
    FechaPipe,
    FechaPipeResumen,
    SpinnerComponent
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ConversorPipe,
    MilesPipe,
    FechaPipe,
    FechaPipeResumen,
    HttpClientModule,
    FormsModule,
    ListErrorsComponent,
    RouterModule,
    SpinnerComponent
  ]
})
export class SharedModule {}
