import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PrestamoModelPost } from './PrestamoModelPost';
import { HeaderService } from '../header.options';
import { MatDialog } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ValidacionErrors } from '../../utileria/validacion.error';
import { DialogOverviewMsg } from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';

@Injectable({ providedIn: 'root' })
export class ApiPrestamosService {
  constructor(
    private httpClient: HttpClient,
    private headers: HeaderService,
    private dialog: MatDialog,
    private validacionError: ValidacionErrors


  ) { }

  postPrestamo(numeroEmpleado: string, idCotizador: string, idProducto: string): Observable<any> {
    const prestamoModel = new PrestamoModelPost();
    prestamoModel.numeroEmpleado = numeroEmpleado;
    prestamoModel.idCotizador = idCotizador;
    prestamoModel.idProducto = idProducto;
    return this.httpClient.post(environment.api_url_adelanto, prestamoModel,
      { headers: this.headers.postHeaderOption(false) }).pipe(
        catchError(e => {
          throw e;
        })
      );
  }

  getPrestamoPorSolicitud(idPrestamo: string): Observable<any> {
    return this.httpClient.get(environment.api_url_prestamos + '/' + idPrestamo,
      { headers: this.headers.getHeaderOption(false) }
    ).pipe(
      catchError(e => {
        if (e.status >= 400 && e.status < 500) {
          this.dialog.closeAll();
          this.openDialogMsg(this.validacionError.getError(e));
      }
  throw e;
      }));
  }

  openDialogMsg(msg: string): void {
    this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true,
        autoFocus: false
      }
    });
  }
}
