export class UserModel {
  sub: string;
  employeeType: string; // Ejemplo: EMPLEADO
  MAIL: string;
  company: string; // Ejemplo: ELEKTRA
  CN: string; // Número de empleado
  SN: string; // Apellido
  workforceID: string; // Número de empleado
  GIVENNAME: string;
  token: string;

  constructor(token: string) {
    this.token = token;
  }
}
