import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { JwtService } from '../jwt.service';

@Injectable({ providedIn: 'root' })
export class AltaDocumentosService {

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  public respuesta: any = null;
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService,
    private httpClient: HttpClient
  ) { }

  public recoverToken() {
    return this.jwtService.getToken();
  }
  // Funcion para obtener el comprobante de domicilio
  public obtenerComprobante() {
   return null;
  }
  // Funcion para obtener la identificacion de persona NAC o EXT
  public obtenerIdentificaciones() {
      return null;

  }

/*    obtenerTokens(): Observable<any> {

    const url = 'http://dev-apigee-alb01-133938325.us-east-1.elb.amazonaws.com/oauth2/v1/token';

    const parametros =  new URLSearchParams();
    parametros.set('grant_type', 'client_credentials');
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded',
                                          'Authorization': 'Basic UFJYZFd1U1J6R3lvWjZyNEJSRG91SEc4NVZ4UTZIaWhsOndPMW5sVnlZTE1vT200T0o=' });
    return this.httpClient.post<any>(
    url, parametros.toString(),
    {headers: httpHeaders}
    );
    } */

    obtenerTokens() {

 
const httpOptions = {
  headers: new HttpHeaders({
  'Authorization': 'Bearer QdrsMMC95isEndtuPyvbnnhHRqEZ',
  'x-ismock': 'true'
  }),
  };
  return this.httpClient.get("http://dev-apigee-alb01-133938325.us-east-1.elb.amazonaws.com/beneficios-socio/consentimientos/v1?numeroEmpleado=\"938445\"&idProducto=1", httpOptions)
  .subscribe(ok => {
  console.log(ok);
  }, error => {
  console.error( "Error es",error);
  });

      }

}
