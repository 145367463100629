import { Component, Host, OnInit, Input } from '@angular/core';
import { HomeComponent } from '../../home.component';

@Component({
  selector: 'app-beneficioekt',
  templateUrl: './beneficioekt.home.html',
  styleUrls: ['./beneficioekt.home.scss']
})
export class BeneficioEKTHomeComponent implements OnInit {

  parentHome: HomeComponent;
  modalVideo = false;

  @Input() activoli;
  constructor( parentHome: HomeComponent) {
    this.parentHome = parentHome;
  }

  ngOnInit(): void {
  }

  mostrarVideo() {
    this.modalVideo = true;
  }

  cerrarVideo() {
    this.modalVideo = false;
  }

  validacionCP() {
    this.parentHome.validacionAvisoPrivacidad(2);
  }
}
