import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutorizaSolicitudFAMComponent } from './autorizasolicitudfam.component';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'autorizafirma',
    component: AutorizaSolicitudFAMComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AutorizaSolicitudFAMRoutingModule {}