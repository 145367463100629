<h2 class="title">2. Alta de documentos.</h2>
<p>Verifica que tus documentos estén actualizados. Puedes digitalizarlos o adjuntarlos en los siguientes formatos: JPG, JPEG, PNG. (Maximo 2MB por archivo)</p>
<div class="trigger">
  <div>
    <h3 class="subtitle">Identificación oficial vigente:</h3>
    <p class="identificacion" >IFE /INE, Pasaporte, Residente Permanente/Temporal</p>
    <p>Da click sobre la imagen y verifica que tu identificación esté actualizada.</p>
    <p>Si es INE/IFE asegúrate de cargar la imagen en anverso y reverso.</p>
    <div *ngFor="let identificacion of expedientesIdentificacion; let indice=index" class="alert alert-warning" role="alert">
      {{identificacion.detalleCondicionamiento.comentario}}
    </div>
    <!------------------- INICIA DROP FILE ------------------->
    <ngfFormData [files]="files1" postName="file" [(FormData)]="sendableFormData" *ngIf></ngfFormData>
    <div class="dragUp">

      <div ngfDrop multiple="1" selectable="1" [(files)]="files1" maxSize="2000000" accept="image/png,image/jpeg,image/jpg" class="well my-drop-zone"
        [fileDropDisabled]="cambiaImagenesIdentificacion()">
        <div [ngClass]="{ 'content_images_two': cambiaImagenesIdentificacion(),'content_images': files1?.length ===1}" *ngFor="let item of files1;let i=index">
          <div class="image" >
            <button tabindex="-1" type="button" class="btn btn-danger btn-xs" (click)="eliminarImagen(i,$event)">x</button>
            <div *ngIf="['image/png','image/jpeg','image/jpg'].indexOf(item.type)>=0" class="previewIcon" name="divMostrarImagen" (click)='mostrarImagen(item,$event)' [ngfBackground]="item"></div>
            <span class="precarga">{{ item.name }}</span>
          </div>
      </div>
        <div class="upload-btn-wrapper">
          <p *ngIf="files1?.length ==0">Arrastra y suelta tu(s) documento(s) aquí.<br>Máximo 2 archivos.<br><span></span></p>
          <button name="btnCarga" class="btn all actualizar">Actualizar Identificación</button>
        </div>
      </div>
    </div>
    <!------------------- FINALIZA DROP FILE ------------------->
    <p><strong>Comprobante de domicilio vigente:</strong></p>
    <p>(Predial / Agua / Luz / Cable-Internet-Teléfono).<br /><strong>No mayor a 3 meses</strong>.</p>
    <p>Da click sobre la imagen y verifica que tú comprobante de domicilio esté actualizado.</p>
    <!------------------- INICIA DROP FILE ------------------->
    <div *ngFor="let comprobante of expedientesComprobante; let indice=index" class="alert alert-warning" role="alert">
      {{comprobante.detalleCondicionamiento.comentario}}
    </div>
    <ngfFormData [files]="files2" postName="file" [(FormData)]="sendableFormData"></ngfFormData>
    <div class="dragUp">

      <div ngfDrop multiple="1" selectable="1" [(files)]="files2" maxSize="2000000" accept="image/png,image/jpeg,image/jpg" class="well my-drop-zone"
        [fileDropDisabled]="cambiaImagenesComprobante()">
        <div class="comprobante" *ngFor="let item of files2;let i=index">
          <div class="image" >
            <button type="button" class="btn btn-danger btn-xs" (click)="eliminarComprobante(i, $event)">x</button>
            <div *ngIf="['image/png','image/jpeg','image/jpg'].indexOf(item.type)>=0" class="previewIcon" name="divMostrarImagen1" (click)='mostrarImagen(item,$event)' [ngfBackground]="item"></div>
            <span class="precarga">{{ item.name }}</span>
          </div>
      </div>
        <div class="upload-btn-wrapper">
            <p *ngIf="files2?.length ==0">Arrastra y suelta tu documento aquí<br><span></span></p>
          <button name="btnCarga1" class="btn all actualizar">Actualizar Comprobante</button>
        </div>
      </div>
    </div>
    <!------------------- FINALIZA DROP FILE ------------------->
  </div>
</div>
<div class="col pad btns_foot">
   <button *ngIf="idProductoRespuesta === ''" class="btn atras" name="btnAtras" (click)="routeFunction('/datossocio')">Atrás</button>
  <button  class="btn cancelar" name="btnCancelar" (click)="openDialogCancelacionesMensaje()">Cancelar</button>
  <button  type="button" name="btnContinuar" class="btn all" (click)="continuar()">{{btnContinuar}}</button>
</div>


<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
    
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  

    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  

    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  

        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  

    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>  
