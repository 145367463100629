import { Component, Inject, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogDataPreview {
  imagen: File;
  urlPdf: Blob;
  showDownloadButton: boolean;
}

@Component({
  selector: 'app-dialog-preview',
  template: `<div >
  <div  class="mat-typography modal_zoom">
      <div *ngIf="data.urlPdf">
      <ngx-extended-pdf-viewer  [src]="data.urlPdf"
      useBrowserLocale = "true" height = "70vh" > </ngx-extended-pdf-viewer >
      </div>
      <div class="image" *ngIf="data.imagen">
          <div class="previewModal" [ngfBackground]="data.imagen">
      </div>
      <div class="image" *ngIf="data.imagen">
          <span class="precarga">{{ data.imagen.name }}</span>
      </div>
    </div>
  </div>
  <div class="btns_centrados">
          <span id="spanDeclinar">
              <a class="btn all declinar" (click)="cerrarModal()">Cerrar</a>
          </span>
      </div>
  </div>`,
  styleUrls: ['dialog-overview-prev.css']
})
export class DialogPreviewComponent implements OnInit {

  disabledButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogPreviewComponent>,
    private render: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPreview) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.disabledButton = true;
  }

  cerrarModal(): void {
    this.dialogRef.close(false);
  }

  pageRendered(e: any) {
  }
}