import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { SpinnerServiceWS } from './spinner.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private spinnerService: SpinnerServiceWS) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const re = /notificaciones/gi;
    if (req.url.search(re) === -1) {
      this.spinnerService.visibility = true;
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      // if the event is for http response
      if (event instanceof HttpResponse) {
        // stop our loader here
        this.spinnerService.visibility = false;
      }
    }, (err: any) => {
      // if any error we stop our loader bar
      this.spinnerService.visibility = false;
    }));
   } else {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      // if the event is for http response
    }));
  }
}
}
