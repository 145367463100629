import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogDataMsg {
  message: string;
  boton: string;
}

@Component({
  selector: 'app-dialog--msg',
  template: `<div class="modalSmall">
  <p>{{data.message}}</p>
  <div class="btns_centrados">
      <button  type="button" class="btn all" (click)="cerrarModal()">{{data.boton}}</button>
  </div>
  <div class="footer">&nbsp;</div>
  </div>`,
  styleUrls: ['dialog-overview-msg.css']
})

export class DialogReintentarComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogReintentarComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataMsg) {
      this.dialogRef.disableClose = true;

    }

  ngOnInit(): void {}

  cerrarModal(): void {
    this.dialogRef.close(false);
  }
}
