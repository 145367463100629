import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../models/user.model';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class ArchivoService {
  private currentUserSubject = new BehaviorSubject<UserModel>({} as UserModel);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
  ) { }

  validarTipoImagen(extImagen): string {
    let content: string;
    switch (extImagen) {
      case 'PNG':
      case 'png': content = 'image/png'; break;
      case 'JPG':
      case 'jpg': content = 'image/jpg'; break;
      case 'JPEG':
      case 'jpeg': content = 'image/jpeg'; break;
      case 'pdf': content = 'application/pdf'; break;
      default: content = '';
    }
    return content;
  }


  blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;
    // Cast to a File() type
    return <File>theBlob;
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  asincronia = (item: File) => {
    const promise = new Promise((resolve, reject) => {
      let res: string;
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = function () {
        if (typeof reader.result === 'string') {
          res = reader.result;
        } else {
          res = reader.result.toString();
        }
        res = res.split(',')[1];
        const binstr = atob(res);
        const buf = new Uint8Array(binstr.length);
        Array.prototype.forEach.call(binstr, function (ch, i) {
          buf[i] = ch.charCodeAt(0);
        });
        resolve(buf);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
    return promise;
  }



}
