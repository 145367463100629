import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { JwtService } from '../../jwt.service';
import { NotificacionModel } from 'src/app/core/models/credito/notificaciones/notificacion.model';
import { HeaderService } from '../header.options';
import { environment } from '../../../../../environments/environment';
import { NotificacionEliminadaModel } from '../../../models/credito/notificaciones/notificacionEliminada.model';

@Injectable({ providedIn: 'root' })
export class NotificacionService {
    notificaciones: NotificacionModel[] = [];
    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    public isAuthenticated = this.isAuthenticatedSubject.asObservable();
    public respuesta: any = null;
    constructor(
        private headers: HeaderService,
        private jwtService: JwtService,
        private httpClient: HttpClient
    ) { }


    public recoverToken() {
        return this.jwtService.getToken();
    }

    public obtenerNotificaciones(numeroEmpleado: string, ordenar: string, limite: string): Observable<any> {
        const parametros = new URLSearchParams();
        parametros.set('numeroEmpleado', numeroEmpleado);
        parametros.set('ordenar', '' + ordenar);
        parametros.set('limite', '' + limite);

       return this.httpClient.get<any>(
          environment.api_url_notificaciones + parametros.toString(),
          { headers: this.headers.getHeaderOption(false) });

    }

    putEliminarNotificacion(notificacionEliminadaModel: NotificacionEliminadaModel): Observable<any> {

            return this.httpClient.put<any>(
               environment.api_url_notificaciones, notificacionEliminadaModel,
               { headers: this.headers.postHeaderOption(false) });
    }

    public obtenerTipoNotificaciones(idProducto: any): Observable<any> {
        const parametros = new URLSearchParams();
        parametros.set('idProducto', idProducto);

       return this.httpClient.get<any>(
          environment.api_url_tipo_notificaciones + parametros.toString(),
          { headers: this.headers.getHeaderOption(false) });

    }

}
