import {Component, Inject, ViewChild, ElementRef, Renderer2, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export interface DialogData {
  titulo: string;
  urlPdf: Blob;
  filePdf: File;
  showDownloadButton: boolean;
}

@Component({
  selector: 'dialog-overview',
  template: `
  <div class="modalDocs">
    <h2 class="modalGral">{{data.titulo}}</h2>
    <div class="mat-typography">
        <div *ngIf="data.urlPdf">
        <ngx-extended-pdf-viewer  [src]="data.urlPdf"
        useBrowserLocale = "true" height = "70vh" > </ngx-extended-pdf-viewer >
        </div>
    </div>
    <div class="btns_left">
        <a *ngIf="data.showDownloadButton" class="btn all descargar"  (click)="myFunction()"
            target="_blank" rel="noopener noreferrer">Descargar
        </a>
    </div>
    <div class="btns_centrados">
        <button type="button" class="btn all" (click)="cerrarModal()" >Estoy de
            acuerdo</button><br>
        <span  tabindex="0">
            <a class="btn all declinar" (click)="declinar()">Declinar</a>
        </span>
    </div>
  </div>
  `,
  styleUrls: ['dialog-overview.css']
})
export class DialogOverview implements OnInit {

  disabledButton: boolean;
  @ViewChild('spanDeclinar') spanDeclinar: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogOverview>,
    private sanitizer: DomSanitizer,
    private render: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.dialogRef.disableClose = true;
    }

  ngOnInit(): void {
    console.log(this.data.urlPdf);
    this.disabledButton = false;
    const blob = this.data.urlPdf;
  }

  myFunction() {
    document.getElementById('secondaryDownload').click();
  }

  cerrarModal(): void {
    this.dialogRef.close(false);
  }

  declinar(): void {
    this.dialogRef.close(true);
  }


}
