
import { NgModule } from '@angular/core';
import { ngfModule } from 'angular-file';
import { SharedModule } from '../../shared';
import { AngularMaterialModule } from '../../shared/layout/angularMaterial/angularMaterial.module';
import { XXSComponent } from '../../core/error/xss.component';

@NgModule({
    imports: [
        AngularMaterialModule,
        SharedModule,
        ngfModule
    ],
    declarations: [
        XXSComponent
    ],
    exports: [
        XXSComponent
    ]
})
export class CreditoModule { }
