const url = 'https://qa.apibaz.com';
const urlOkta = 'https://gruposalinas-oie.oktapreview.com'
const apigeeoauth = '/oauth2/v1/';
const url_onpremise = 'https://dev-api.bancoazteca.com.mx:8080';
export const environment = {
  production: false,
  redirectUri: 'https://portal.beneficiosfinancierosdev.com',
  redirectLogoutOkta: 'https://auth-dev.gruposalinas.com.mx/app/UserHome',
  okta_url_authorize: urlOkta + apigeeoauth + 'authorize?',
  okta_url_token: urlOkta + apigeeoauth + 'token',
  okta_url_user_info: urlOkta + apigeeoauth + 'userinfo',
  okta_url_logout: urlOkta + apigeeoauth + 'logout',
  okta_response_type: 'code',
  okta_client_id: '0oa3vve26or1coDMg1d7',
  okta_code_challenge_method: 'S256',
  okta_scope: 'openid+profile+groups',
  okta_state: 'validacion',
  api_client_id: 'KZM16MzRj9sSp1YBGxqtGPl38qXWCkBN',
  api_client_secret: 'HbeWNYKfTNmGRtwD',
  api_url_token: url + apigeeoauth + 'token',
  api_url_adelanto: url + '/beneficios-socio/adelanto-efectivo/v1',
  api_url_adelanto_cotizaciones: url + '/beneficios-socio/adelanto-efectivo/v1/cotizaciones?',
  api_url_adelanto_cotizadores: url + '/beneficios-socio/adelanto-efectivo/v1/cotizadores?',
  api_url_autorizaciones: url + '/beneficios-socio/autorizaciones/v1/tokens/validaciones',
  api_url_bfs_validaciones: url + '/beneficios-socio/adelanto-efectivo/v1/validaciones?',
  api_url_consentimiento: url + '/beneficios-socio/consentimientos/v1?',
  api_url_cancelaciones: url + '/beneficios-socio/socios/v1/catalogos/cancelaciones',
  api_url_creditos: url + '/beneficios-socio/creditos/v1/capacidades_pago?',
  api_url_credito: url + '/beneficios-socio/creditos/v1?',
  api_url_documentos: url + '/beneficios-socio/documentos/v1',
  api_url_expedientes: url + '/beneficios-socio/documentos/v1/expedientes?',
  api_url_notificaciones: url + '/beneficios-socio/notificaciones/v1?',
  api_url_obtenertokenapi: url + '/oauth2/v1/token/external?',
  api_url_prestamos: url + '/beneficios-socio/prestamos/v1',
  api_url_prestamos_cotizadores: url + '/beneficios-socio/prestamos/v1/cotizadores?',
  api_url_prestamos_cotizaciones: url + '/beneficios-socio/prestamos/v1/cotizaciones',
  api_url_prestamos_plazos: url + '/beneficios-socio/prestamos/v1/catalogos/plazos?',
  api_url_productos: url + '/beneficios-socio/productos/v1/configuraciones?',
  api_url_ps_validaciones: url + '/beneficios-socio/prestamos/v1/validaciones?',
  api_url_referencias: url + '/beneficios-socio/socios/v1/referencias?',
  api_url_socios: url + '/beneficios-socio/socios/v1?',
  api_url_socios_parentescos: url + '/beneficios-socio/socios/v1/catalogos/parentescos',
  api_url_socios_tipo_direcciones: url + '/beneficios-socio/socios/v1/catalogos/tipo_direcciones',
  api_url_socios_antiguedad_residencias: url + '/beneficios-socio/socios/v1/catalogos/antiguedad_residencias',
  api_url_solicitudes: url + '/beneficios-socio/creditos/v1/solicitudes?',
  api_url_zonificacion: url_onpremise + '/cobranza_credito/investigacion-cobranza/cartografia/catalogos/v1/colonias/detalles?',
  api_url_tipo_notificaciones: url + '/beneficios-socio/notificaciones/catalogos/v1/tipos?',
  api_client_id_onpremise: 'min5mfyGR7K271gapu4yINUI6yhTOsna',
  api_client_secret_onpremise: '2eMqXfbBAn2AvC63',
  api_url_token_onpremise: url_onpremise + apigeeoauth + 'token',
  contra_crypto: 'beneficios'
};
