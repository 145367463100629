
export class DireccionModel {
  idDireccion: number;
  calle: String;
  idColonia: number;
  colonia: String;
  idDelegacionMunicipio: number;
  delegacionMunicipio: String;
  idEstado: number;
  estado: String;
  codigoPostal: String;
  numExterior: String;
  numInterior: String;
  referenciaDomicilio: String;
  calleAdelante: String;
  calleAtras: String;
  calleDerecha: String;
  calleIzquierda: String;
  numTiendaCercana: String;
}
