import { Component,  OnInit, Input } from '@angular/core';
import { HomeComponent } from '../../home.component';

@Component({
  selector: 'app-inversion-activa',
  templateUrl: './inversion-activa.component.html',
  styleUrls: ['./inversion-activa.component.scss']
})
export class InversionActivaComponent implements OnInit {

  parentHome: HomeComponent;
  modalVideo = false;

  @Input() activoli;
  constructor( parentHome: HomeComponent) {
    this.parentHome = parentHome;
  }

  ngOnInit(): void {
  }

  mostrarVideo() {
    this.modalVideo = true;
  }

  cerrarVideo() {
    this.modalVideo = false;
  }

  validacionCP() {
    this.parentHome.validacionAvisoPrivacidad(2);
  }
}
