<header>
    <div class="container">
        <div class="row">
            <div class="col-12 pad">
                <a class="logo_GS"><img src="../../../../assets/img/logo_GS.svg" alt="::: Grandes Beneficios" /></a>
                <nav class="navbar navbar-expand-lg navbar-light">
                    <div class="navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto">
                            <!--------------------- Inicio Sesión ---------------------->
                            <li class="nav-item perfilSesion" *ngIf="!sesionActiva && !guatemala" tabindex="-1">
                                <a class="nav-link nameSocio" (click)="onManejoSesion()">Iniciar sesión<span class="arrow"></span></a>
                            </li>
                            <!---------------------------------------------------------->
                            <li class="nav-item Datos" *ngIf="sesionActiva" tabindex="-1">
                                <div class="dropdown">
                                    <img alt="imagenPerfil" class="perfilSocio" src="{{urlImagen}}"
                                        (error)="cargarImagenPorDefecto($event)" />
                                    <a class="dropdown-toggle nav-link nameSocio" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false"><span class="bienvenido">¡Bienvenido!
                                        </span><br>{{ nombreEmpleado }} <span class="arrow"></span></a>
                                    <div class="dropdown-menu dropdown-menu-right perfilSocioHeader"
                                        aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item perfilSesion" (click)="routeToPerfilSocio()">Mi
                                            perfil</a>
                                        <a class="dropdown-item cerrarSesion" (click)="cerrarSesion()">Cerrar sesión</a>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item dropdown" *ngIf="sesionActiva">
                                <a [attr.disabled]="true"
                                    [ngClass]="{'nav-link':true,' dropdown-toggle':true,'notifications':true, 'active':active==true}"
                                    id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false" tabindex="-1">
                                    <p>Notificaciones</p>
                                </a>
                                <div class="dropdown-menu" #dropdwn aria-labelledby="navbarDropdown">
                                    <span></span>
                                    <ul class="not" tabindex="-1" *ngIf="notificaciones.length>0">
                                        <li tabindex="-1"
                                            *ngFor="let item of notificaciones | slice:0:2; let indice=index">
                                            <a [ngClass]="{'visto': item.idEstatus==2, '': item.idEstatus==1}"
                                                (click)='abrirNotificacion(item, $event)'><span class="circle">BF</span>
                                                <p><strong>{{item.titulo}}</strong><br>{{item.mensaje}}</p>
                                            </a>
                                        </li>
                                    </ul>
                                    <a class="verMas" (click)='mostrarNotificacion()'
                                        *ngIf="notificaciones.length>0">Ver más</a>
                                    <p class="errorOne" *ngIf="validaNotificacionesConsulta()">No tienes notificaciones
                                        pendientes</p>
                                    <p class="errorTwo">{{errorConsulta}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
                <nav aria-label="breadcrumb" class="breadcrumbSocios">
                    <ol>
                        <li class="breadcrumb-item">
                            <a (click)="eventBreadcrumb('/home')">Grandes Beneficios</a>
                        </li>
                        <li class="breadcrumb-item beneficios_f">
                            <a (click)="eventBreadcrumb('/home')">Beneficios financieros</a>
                        </li>
                        <li class="breadcrumb-item" *ngIf="showCreditosBC">
                            <a (click)="eventBreadcrumb('/home')">{{uriSeccion}}</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page" *ngIf="showProducto">{{uriProducto}}</li>
                    </ol>
                </nav>

            </div>
            </div>
            <div class="row">
                <div class="offset-md-1 col-md-9">
                    <h1 id="tituloProducto" class="title text-center" #tituloProducto>{{tituloPrincipal}}</h1>
                </div>
                <div class="col-md-1">
                    <img class="logoBan01" *ngIf="showProducto && showLogoBaz"  src="../../../../assets/img/logo_Ban_01.svg">

                </div>
            </div>
    </div>
    <!-- Modal -->
    <div class="abrir-modal animacion fadeIn" *ngIf="modalNotificaciones">
        <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <button (click)="cerrarFoto()" type="button" class="close cmodal" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body notificaciones-container">
                        <ul class="not">
                            <li *ngFor="let item of notificaciones; let indice=index">
                                <a [ngClass]="{'visto': item.idEstatus==2, '': item.idEstatus==1}"
                                    (click)='abrirNotificacion(item,$event)'><span
                                        class="circle">BF</span><strong>{{item.titulo}}</strong><br>{{item.mensaje}}</a><button
                                    type="button" class="close"
                                    (click)='eliminarNotificacion(item,$event)'><span>&times;</span></button>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<script>
    if (self === top) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }

    function redireccionAlSitio() {
        try {
            var surl = self;
            if (validateURL(surl))
                top.location.href = surl;
            else {
                throw new InvalidURLException();
            }
        } catch (e) {
            if (e instanceof InvalidURLException)
                alert(e.message);
        }
    }

    function InvalidURLException() {
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";
        this.toString = function () {
            return this.message
        };
    }

    function validateURL(surl) {
        var url = parseURL(surl);
        var urlHostname = url.hostname.trim();

        if (urlHostname == '') {
            return true;
        } else {
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {
                return true;
            } else {
                return false;
            }
        }
    }

    function parseURL(url) {
        var a = document.createElement('a');
        a.href = url;
        return {
            source: url,
            protocol: a.protocol.replace(':', ''),
            hostname: a.hostname,
            host: a.host,
            port: a.port,
            query: a.search,
            params: (function () {
                var ret = {},
                    seg = a.search.replace(/^\?/, '').split('&'),
                    len = seg.length, i = 0, s;
                for (; i < len; i++) {
                    if (!seg[i]) { continue; }
                    s = seg[i].split('=');
                    ret[s[0]] = s[1];
                }
                return ret;
            })(),
            file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
            hash: a.hash.replace('#', ''),
            path: a.pathname.replace(/^([^\/])/, '/$1'),
            relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
            segments: a.pathname.replace(/^\//, '').split('/')
        };
    }
</script>