import { NgModule } from '@angular/core';
import { DialogOverviewMsg } from './modalMaterialMsg/dialog-overview-msg';
import { DialogOverview } from './modalMaterial/dialog-overview';
import { DialogPreviewComponent } from './modalPreview/dialog-overview-prev';
import { DialogValidacionComponent } from './modalMaterial.1/dialog-overview';
import { DialogReintentarComponent } from './modalMaterialReintentar/dialog-overview-msg';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/shared/layout/angularMaterial/angularMaterial.module';
import { ngfModule, ngf } from 'angular-file';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DialogCancelacionComponent } from './modalMaterialCancelaciones/dialog-overview-msg1';
import { DialogMensajeComponent } from './modalMaterialCancelaciones.1/dialog-overview-msg1';
import { DialogCondicionadoComponent } from './modalMaterialCondicionado/dialog-overview-msg1';
import { DialogRecompraComponent } from './modalRecompra/dialog-overview-msg1';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ngfModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [
    DialogOverviewMsg,
    DialogOverview,
    DialogPreviewComponent,
    DialogValidacionComponent,
    DialogCancelacionComponent,
    DialogReintentarComponent,
    DialogMensajeComponent,
    DialogCondicionadoComponent,
    DialogRecompraComponent
  ],
  exports: [
    DialogOverviewMsg,
    DialogOverview,
    DialogPreviewComponent,
    DialogValidacionComponent,
    DialogCancelacionComponent,
    DialogReintentarComponent,
    DialogMensajeComponent,
    DialogCondicionadoComponent,
    DialogRecompraComponent
  ],
  entryComponents: [
    DialogOverviewMsg,
    DialogOverview,
    DialogPreviewComponent,
    DialogValidacionComponent,
    DialogReintentarComponent,
    DialogCancelacionComponent,
    DialogMensajeComponent,
    DialogCondicionadoComponent,
    DialogRecompraComponent
]
})
export class DialogModule {}
