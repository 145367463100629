
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core';
import { DialogMensajeComponent } from '../../../../shared/components/modales/modalMaterialCancelaciones.1/dialog-overview-msg1';
import { DialogCancelacionComponent } from '../../../../shared/components/modales/modalMaterialCancelaciones/dialog-overview-msg1';
import { ApiDocumentosService } from '../../../../core/services/credito/api.documentos/api.documentos';
import { DialogOverviewMsg } from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { ConsentimientoModel } from '../../../../core/services/credito/api.consentimiento/consentimiento.model';
import { ApiConsentimientoService } from '../../../../core/services/credito/api.consentimiento/api.consentimiento.service';
import { EstatusConsentimiento, TipoDocumento, TipoProducto, Intentos } from 'src/app/core/services/utileria/utileria.enum';
import { DialogReintentarComponent } from 'src/app/shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { ValidacionErrors } from 'src/app/core/services/utileria/validacion.error';

@Component({
  selector: 'app-autoriza-solicitud',
  templateUrl: './autoriza.solicitud.component.html'
})
export class AutorizaSolicitudComponent implements OnInit {

  public visible = {0: true};
  mostrarAdE: boolean;
  mostrarPs: boolean;
  numeroEmpleado: string;
  idProducto: number;
  intentos_api: number;
  valida: ValidacionErrors;
  contratoPdfADE;
  avisoPrivacidad;
  caratulaPP;
  solicitudPP;
  tablaAmortizacion;

  error: any;
  page = 1;
  rotation = 0;
  zoom = 1.0;
  originalSize = true;
  pdf: any;
  renderText = true;
  isLoaded = false;
  stickToPage = false;
  showAll = true;
  autoresize = true;
  fitToPage = false;
  outline: any[];

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private apiDocumentos: ApiDocumentosService,
    private consentimientoService: ApiConsentimientoService
  ) {
    this.valida = new ValidacionErrors();
  }

  ngOnInit() {
    if (window.sessionStorage) {
      const idProducto = window.sessionStorage ? sessionStorage.getItem('producto_id') : '';
      if (idProducto !== null && idProducto !== undefined && idProducto.trim() !== '') {
        this.getStorageInfo();
        this.mostrarPDFs();
        this.mostrarDocumentos(this.idProducto);
      } else {
        setTimeout(() => {
          this.openDialogMsg('Por favor selecciona un producto para continuar', true);
        });
      }
    }
  }

  getStorageInfo(): void {
    this.intentos_api = 1;
    this.numeroEmpleado = this.userService.getNumeroEmpleado();
    this.idProducto = +sessionStorage.getItem('producto_id');
  }

  mostrarPDFs(): void {
    if (this.idProducto === TipoProducto.ADELANTO_EFECTIVO) {
      this.mostrarAdE = true;
      this.mostrarPs = false;
    } else if (this.idProducto === TipoProducto.PRESTAMO) {
      this.mostrarAdE = false;
      this.mostrarPs = true;
    }
  }

  set src(input: Blob) {
    this.contratoPdfADE = input;
  }

  activateTab(tab: number): void {
    this.hideOtherPDFs();
    setTimeout(() => {
      this.visible[tab] = true;
    }, 100);
  }

  hideOtherPDFs(): void {
    this.visible[0] = false;
    this.visible[1] = false;
    this.visible[2] = false;
    this.visible[3] = false;
  }

  mostrarDocumentos(idProducto: number) {
    let idDocumentos = [];
    if (idProducto === TipoProducto.ADELANTO_EFECTIVO) {
      idDocumentos = [
        TipoDocumento.CONTRATO_DE_ADE,
        TipoDocumento.AVISO_DE_PRIVACIDAD
      ];
    } else if (idProducto === TipoProducto.PRESTAMO) {
      idDocumentos = [
        TipoDocumento.CONTRATO_DE_ADE,
        TipoDocumento.AVISO_CARTA_TOR,
        TipoDocumento.SOLICITUD_PRESTAMO,
        TipoDocumento.AVISO_DE_PRIVACIDAD
      ];
    }
    if(this.idProducto===1){
      this.obtenerDocumentosAde(idDocumentos);

    }else{
      this.obtenerDocumentosPP(idDocumentos);
    }


  }
  obtenerDocumentosAde(idDocumentos :any){
    this.apiDocumentos
    .getObtenerDocumentos(this.numeroEmpleado, idDocumentos)
    .subscribe(response => {
      const documentos = response.resultado.documentos;
      // Metodo para recuperar los archivos de BD CON
      for (let i = 0; i < documentos.length; i++) {
        if (documentos[i].ext !== 'null') {
          // Get the content type of the image
          const contentType = this.validarTipoImagen(documentos[i].ext);
          // get the real base64 content of the file
          const base64 = documentos[i].base64;
          // Convert it to a blob to upload
          const blob = this.b64toBlob(base64, contentType, null);
          if (documentos[i].idDocumento === 498) {
            this.contratoPdfADE = URL.createObjectURL(blob);
          } else if (documentos[i].idDocumento === 392)  {
            this.avisoPrivacidad = URL.createObjectURL(blob);
          }
        }
      }
    }, err => {
      this.errorAPIConexion(err, 1);
    }
  );

  }

  obtenerDocumentosPP(idDocumentos :any){
    this.apiDocumentos
    .getObtenerDocumentosPrestamo(this.numeroEmpleado, idDocumentos,this.idProducto)
    .subscribe(response => {
      const documentos = response.resultado.documentos;
      // Metodo para recuperar los archivos de BD CON
      for (let i = 0; i < documentos.length; i++) {
        if (documentos[i].ext !== 'null') {
          // Get the content type of the image
          const contentType = this.validarTipoImagen(documentos[i].ext);
          // get the real base64 content of the file
          const base64 = documentos[i].base64;
          // Convert it to a blob to upload
          const blob = this.b64toBlob(base64, contentType, null);
          if (documentos[i].idDocumento === 498) {
            this.contratoPdfADE = URL.createObjectURL(blob);
          } else if (documentos[i].idDocumento === 484)  {
            this.caratulaPP= URL.createObjectURL(blob);
          }else if (documentos[i].idDocumento === 330)  {
            this.solicitudPP= URL.createObjectURL(blob);
          }else if (documentos[i].idDocumento === 392)  {
            this.avisoPrivacidad = URL.createObjectURL(blob);
          }
        }
      }
    }, err => {
      this.errorAPIConexion(err, 1);
    }
  );

  }

  errorAPIConexion(error, option: number): void {
    if (error.status === 400 || (error.status > 402 && error.status < 500)) {
      this.dialog.closeAll();
      this.openDialogMsg(this.valida.getError(error), true);
    } else if (error.status === 500 && this.intentos_api < Intentos.MAX_INTENTOS) {
      this.intentos_api++;
      this.dialog.closeAll();
      this.openDialogReintentar('Ocurrió un problema de conexión. Inténtalo nuevamente', 'Reintentar', option);
    } else if (error.status === 500 && this.intentos_api >= Intentos.MAX_INTENTOS) {
      this.dialog.closeAll();
      this.intentos_api = 1;
      this.openDialogMsg('Ocurrió un error al conectar con los servicios. Por favor intente más tarde', true);
    }
  }

  openDialogReintentar(msg: string, boton: string, option: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      switch (option) {
        case 1: this.mostrarDocumentos(this.idProducto); break;
        case 2: this.guardarConsentimientos(); break;
      }
    });
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;
    // Cast to a File() type
    return <File>theBlob;
  }

  validarTipoImagen(extImagen): string {
    let content: string;
    switch (extImagen) {
      case 'png':
        content = 'image/png';
        break;
      case 'jpg':
        content = 'image/jpg';
        break;
      case 'jpeg':
        content = 'image/jpeg';
        break;
      case 'pdf':
        content = 'application/pdf';
        break;
    }
    return content;
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  routeFunction(ruta: string) {
    this.router.navigate([ruta], { skipLocationChange: false });
  }

  openDialogCancelacionesMensaje(): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        message: '¿Estás seguro que deseas salir? No se guardará el avance.',
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openDialogCancelaciones();
      }
    });
  }

  openDialogCancelaciones(): void {
    this.dialog.open(DialogCancelacionComponent, {
      width: '500px',
      data: {
        message: '',
        opciones: true,
        disableClose: true,
        rutaDestino: '/home'
      },
      autoFocus: false
    });
  }

  openDialogMsg(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
    });
  }

  guardarConsentimientos() {
    const request = new ConsentimientoModel();
    request.numeroEmpleado = this.numeroEmpleado;
    request.idClausula = this.idProducto === 1 ? TipoDocumento.CONTRATO_DE_ADE : TipoDocumento.CONTRATO_DE_PRESTAMO_SOCIO ;
    request.idProducto = this.idProducto;
    request.estatus = EstatusConsentimiento.ESTATUS_APROBADO;
    this.consentimientoService.postConsentimiento(request).subscribe(() => {
      this.routeFunction('/autorizafirma');
    }, err => {
      this.errorAPIConexion(err, 1);
    });
  }

}
