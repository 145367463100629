<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>

<h1 class="titleHome">¡Tu bienestar financiero es lo más importante!</h1>

<p> En esta sección podrás encontrar algunos consejos y recomendaciones que te ayudarán a tener finanzas personales sanas</p>

<p>Aprovecha estas herramientas para el logro tus objetivos y metas personales</p>

<p class="textoverde"><strong>Tema 1. Introducción a la Educación Financiera</strong></p>
 

<p>
<a class="video" (click)="mostrarVideo()" target="_blank" rel="noopener noreferrer">1.1 ¿Qué es Educación Financiera?</a>
</p>

<p class="textoverde"><strong>Tema 2. Herramientas de apoyo</strong></p>
<p>
    <a class="video" (click)="mostrarVideo2()" target="_blank" rel="noopener noreferrer">2.1 Identifica tus ingresos y gastos</a>
</p>

<p>
    <a class="folleto" href="../../../../../../assets/docs/home/formatoIngresosGastos.xlsx" target="_blank" rel="noopener noreferrer">2.2 Formato para registro de ingresos y gastos</a>
</p>

<br>
<br>


<hr class="prodBottom">
<div class="row">
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
      </div>
      <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
      </div>
</div>


<div class="abrir-modal animacion fadeIn" *ngIf="modalVideoIntro">
    <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <button (click)="cerrarVideo()" type="button" class="close cmodal" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe title="" class="embed-responsive-item"
                        src="../../../../../../assets/videos/IntroduccionEducacionFinanciera.mp4"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="abrir-modal animacion fadeIn" *ngIf="modalVideoHerramientas">
    <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <button (click)="cerrarVideo2()" type="button" class="close cmodal" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe title="" class="embed-responsive-item"
                        src="../../../../../../assets/videos/identificaIngresosGastos.mp4"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>