import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { HomeComponent } from './shared/layout/home/home.component';
import { XXSComponent } from './core/error/xss.component';
import { AuthGuardService } from './core/services/auth/auth-guard.service';
import { AuthGuardAccessTokenService } from './core/services/auth/auth-guard-token.service';
import { PortalGuatemalaComponent } from './shared/layout/home/portal-guatemala/portal-guatemala.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuardAccessTokenService] },
  { path: 'externalRedirect', canActivate: [externalUrlProvider], component: XXSComponent },
  { path: 'divisas', component: HomeComponent},
  { path: 'prestamo-personal', component: HomeComponent},
  { path: 'venta-empleado', component: HomeComponent},
  { path: 'tarjeta-oro', component: HomeComponent},
  { path: 'adelanto-efectivo', component: HomeComponent},
  { path: 'tpremia', component: HomeComponent},
  { path: 'prestamo-auto', component: HomeComponent},
  { path: 'inversion-activa', component: HomeComponent},
  { path: 'presta-prenda', component: HomeComponent},
  { path: '?guatemala', component: PortalGuatemalaComponent},
  { path: 'aducacion-financiera', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled'  })],
  exports: [RouterModule],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_self');
      }
    },
    {
      provide: AuthGuardService,
      useValue: () => {
        return false;
      }
    },
    {
      provide: AuthGuardAccessTokenService,
      useValue: () => {
        return false;
      }
    }
  ]
})
export class AppRoutingModule {}
