import { NgModule } from '@angular/core';
import { MapaGoogleComponent } from './mapa.component';
@NgModule({
  imports: [
  ],
  declarations: [
    MapaGoogleComponent
  ],
  exports: [
    MapaGoogleComponent
  ]
})
export class MapaModule {}