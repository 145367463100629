import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import {
  HeaderComponent,
  BodyComponent,
  FooterComponent,
  AsideComponent
} from './shared';
import { CoreModule } from './core';

import { SharedModule } from './shared/shared.module';
import { HomeModule } from './shared/layout/home/home.module';
import { PaginasComunesModule } from './components/credito/comunes/paginas.comunes.module';
import { AuthGuardService } from './core/services/auth/auth-guard.service';
import { AuthGuardAccessTokenService } from './core/services/auth/auth-guard-token.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreditoModule } from './components/credito/credito.module';
import { APP_BASE_HREF } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { BnNgIdleService } from 'bn-ng-idle';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BodyComponent,
    FooterComponent,
    AsideComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HomeModule,
    CoreModule,
    PaginasComunesModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    CreditoModule,
    NgxMaskModule.forRoot()
  ],
  providers: [BnNgIdleService, AuthGuardService, AuthGuardAccessTokenService,
    { provide: APP_BASE_HREF, useValue: '/home' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
