import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SpinnerService {

  spinnerText: String;

  constructor() {}

  setText(text: String): void {
    this.spinnerText = text;
  }

  abrirSpinner(textoSpinner: String): void {
    this.spinnerText = textoSpinner;
    document.getElementsByTagName('body')[0].classList.add('modal-open');
    document.getElementById('spinner-datossocio').removeAttribute("hidden");
    this.cerrarSpinner();
  }

  abrirSpinner1(textoSpinner: boolean): void {
    this.spinnerText = '';
    if (textoSpinner === true) {
      document.getElementsByTagName('body')[0].classList.add('modal-open');
      document.getElementById('spinner-datossocio').removeAttribute("hidden");
    } else {
      document.getElementById('spinner-datossocio').setAttribute("hidden", "");
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
    }
  }

  cerrarSpinner(): void {
    setTimeout(() => {
      document.getElementById('spinner-datossocio').setAttribute("hidden", "");
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
    }, 3000);
  }

  cerrarSpinnerOndestroy(): void {
      document.getElementById('spinner-datossocio').setAttribute("hidden", "");
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
    
  }
}
