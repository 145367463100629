import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Directive()
@Injectable()
export class HomeService {

  llamarMetodoADE = false;
  llamarMetodoCP = false;
  actualizarPerfil = false;
  mostrarCartaTor = false;
  @Output() openADE: EventEmitter<boolean> = new EventEmitter();
  @Output() openCP: EventEmitter<boolean> = new EventEmitter();
  @Output() openPerfil: EventEmitter<boolean> = new EventEmitter();
  @Output() openCartaTor: EventEmitter<boolean> = new EventEmitter();

  iniciarADE() {
    this.llamarMetodoADE = !this.llamarMetodoADE;
    this.openADE.emit(this.llamarMetodoADE);
  }

  iniciarCP() {
    this.llamarMetodoCP = !this.llamarMetodoCP;
    this.openCP.emit(this.llamarMetodoCP);
  }

  iniciarPerfil() {
    this.actualizarPerfil = true;
    this.openPerfil.emit(this.actualizarPerfil);
    this.actualizarPerfil = false;

  }

  iniciarCartaTor(){
    this.mostrarCartaTor = !this.mostrarCartaTor;
    this.openCartaTor.emit(this.mostrarCartaTor);

  }
}
