import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogDataMsg {
  message: string;
}

@Component({
  selector: 'dialog-overview-msg',
  template: `
  <div class="modalSmall">
  <p [innerHTML]="data.message"></p>
    <div class="btns_centrados">
        <button  type="button" class="btn all" (click)="cerrarModal()">OK</button>
    </div>
    <div class="footer">&nbsp;</div>
  </div>`,
  styleUrls: ['dialog-overview-msg.css']
})
export class DialogOverviewMsg implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewMsg>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataMsg) {
      this.dialogRef.disableClose = true;

    }

  ngOnInit(): void {}

  cerrarModal(): void {
    this.dialogRef.close(false);
  }
}
