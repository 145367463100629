<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/FOLLETO_DGS.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/INFOGRAFIA_DGS.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
    <a class="video" (click)="mostrarVideo()"> Video</a>
    <a class="faqs" href="../../../../../../assets/docs/home/PREGUNTAS_FRECUENTES_DGS.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">¡Aquí tus dólares y tu esfuerzo valen más!</h1>
<p class="textoverde"> <strong>¿Qué es?</strong></p>
<p>Beneficio con el cual puedes <strong>comprar o vender</strong> a través de la <strong class="textoverde">App </strong>  <img class = "imgWidthe" src="../../../../assets/img/baz.png" > <sup>1</sup> y en  las sucursales, dólares americanos, canadienses y euros con el mejor precio</p>
<p class="textoverde"><strong>¿Para quién es?</strong></p>
<p>Todos los Colaboradores de GS</p>
<p class="textoverde"><strong>¿Qué beneficios exclusivos te ofrece?</strong></p>
<ul class="contenido">
    <li> <strong>Un tipo de cambio preferencial acorde a tu antigüedad </strong></li>
    <li>Cambia <strong>dólares americanos</strong> de forma <strong>inmediata</strong> desde la app de <strong>Banco Azteca</strong></li>
    <!-- <li>Cambia <strong>dólares canadienses, americanos y euros</strong> en cualquier <strong>sucursal de Banco Azteca</strong></li> -->
    <li>Sin antigüedad mínima</li>
</ul>
<p class="textoverde"><strong>Características</strong></p>
<p>Tipo de cambio preferencial de acuerdo a tu antigüedad al cambiar <strong>dólares americanos, canadienses y euros</strong> en sucursal o desde la <strong class="textoverde">App </strong>  <img class = "imgWidthe" src="../../../../assets/img/baz.png" >. Revisa la siguiente tabla:</p>
<div  class="container" >
    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-10 col-xl-8 ">
           
            <img  style ="width: 100%;" src="../../../../assets/img/tablaDGS.png" />
            
          </div>

    </div>
</div>
<div  class="container" >
    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-10 col-xl-8 ">
           
            <p> <strong>El beneficio lo verás reflejado en tu ticket al concluir la operación en sucursal</strong></p>
            
          </div>

    </div>
</div>

<br>

<p><strong>App  Banco Azteca<sup>2</sup></strong></p>
<p>Desde tu cuenta de nómina Banco Azteca:</p>
<ul class="contenido">
    <li>Compra desde <strong>$20</strong> hasta <strong>$30,000*</strong> dólares americanos</li>
    <li>Vende desde <strong>$20</strong> hasta <strong>4,000*</strong> dólares americanos</li>
</ul>

<p><strong>Sucursales </strong></p>
<ul class="contenido">
    <li>Compra desde <strong>$2</strong> hasta <strong>$30,000*</strong> dólares americanos o su equivalente en euros o dólares canadienses</li>
    <li>Vende desde <strong>$2</strong> hasta <strong>$4,000*</strong> dólares americanos o su equivalente en euros o dólares canadienses</li>
    <li>La compra y venta mínima para euros y dólares canadienses es de <strong>$5 dólares</strong></li>
</ul>
<p class="textoverde"><strong>Requisitos</strong></p>
<ul class="contenido">
    <li>Tener cuenta de Nómina Grupo Salinas</li>
    <li>Presentar identificación Oficial (INE, IFE o Pasaporte vigente)</li>
</ul>
<div class="subindices">
    <p><sup>*</sup> Montos máximo mensuales</p>
    <p><sup>1</sup> Beneficio exclusivo solo en la compra o venta de dólares americanos a través de la <strong class="textoverde">App </strong> <img class = "imgWidthe" src="../../../../assets/img/baz.png" > </p>
    <p><sup>2</sup> Tienes 3 días (Contando como día uno, cuando realizaste la cotización en la App) para acudir a la sucursal con el código QR a concluir la operación en ventanilla</p>

</div>
<br>
<br>
<div class="row">
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-12"  >
        <a class="btn all inicio_sec button" href="../../../../../../assets/docs/home/InfograficoGuardaditoGO.pdf" style= "text-decoration: none;" target="_blank" rel="noopener noreferrer" >Guardadito GO</a>
     </div>
</div>
<div class="row">
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
      </div>
      <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
      </div>
</div>
<hr class="prodBottom">
<p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
<p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> 55 7099 1199 <br> Opción 5</p>
<div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
    href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div><br><br><br>

<div class="abrir-modal animacion fadeIn" *ngIf="modalVideo">
    <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <button (click)="cerrarVideo()" type="button" class="close cmodal" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe title="" class="embed-responsive-item"
                        src="../../../../../../assets/videos/VideoCrediAuto.mp4"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>