import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { TokenApiService } from '../../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private tokenApiService: TokenApiService) { }

  formatErrors(error: any) {
    return throwError(error.error);
  }

  getHeaderOption(isMock: boolean) {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + sessionStorage.getItem('tokenAPI'),
      'x-ismock': isMock ? 'true' : 'false'
    });
  }

  postHeaderOption(isMock: boolean) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'x-ismock': isMock ? 'true' : 'false',
      'Authorization': 'Bearer ' + sessionStorage.getItem('tokenAPI')
    });
  }

  getTokenApi(): string {
    const token = this.tokenApiService.token;

    if (token != null) {
      return token;
    }
    return '';
  }

  getNumeroEmpleado(): string {
    return sessionStorage.getItem('numeroEmpleado');
  }

  getHeaderOptionOnpremise(isMock: boolean) {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + sessionStorage.getItem('tokenApiOnpremise'),
      'x-ismock': isMock ? 'true' : 'false'
    });
  }
}
