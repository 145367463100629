import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'fechaResumen'})
export class FechaPipeResumen implements PipeTransform {
    transform(fecha: string): string {
        let dia = fecha.substr(0, 2);
        const mes = fecha.substr(3, 2);
        const anio = fecha.substr(6, 4);
        console.log('dia' + dia + 'mes' + 'año' + anio );
        let mesString: string;
        switch (mes) {
            case '01': { mesString = 'Enero'; break; }
            case '02': { mesString = 'Febrero'; break; }
            case '03': { mesString = 'Marzo'; break; }
            case '04': { mesString = 'Abril'; break; }
            case '05': { mesString = 'Mayo'; break; }
            case '06': { mesString = 'Junio'; break; }
            case '07': { mesString = 'Julio'; break; }
            case '08': { mesString = 'Agosto'; break; }
            case '09': { mesString = 'Septiembre'; break; }
            case '10': { mesString = 'Octubre'; break; }
            case '11': { mesString = 'Noviembre'; break; }
            case '12': { mesString = 'Diciembre'; break; }
        }
        if (dia.substr(0, 1) === '0') {
            dia = dia.substr(1, 2);
        }
        return dia + ' de ' + mesString + ' del ' + anio;
    }
}
