import { Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'milesentero'})
export class MilesPipe implements PipeTransform {
    transform(numero: string) {
        if (numero !== undefined && numero !== '--') {
            numero += '';
        const separador = ','; // separador para los miles
        const sepDecimal = '.'; // separador para los decimales
        const simbol = '$';
        const splitStr = numero.split('.', 2);
        let splitLeft = splitStr[0];
        const splitRight = splitStr.length > 1 ? splitStr[1].length > 1 ? sepDecimal + splitStr[1] : sepDecimal + splitStr[1] + '0' : '.00';
        const regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
        }
            return simbol + splitLeft;
        } else {
            return '--';
        }
    }

}
