export class CancelacionModel {

    numeroEmpleado: string;
    idCancelacion: number;
    idProducto: number;

    constructor(numeroEmpleado: string, idCancelacion: number,
        idProducto: number) {
            this.numeroEmpleado = numeroEmpleado;
            this.idCancelacion = idCancelacion;
            this.idProducto = idProducto;
    }
}
