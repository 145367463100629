import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReferenciaModel, RequestReferenciasModel } from '../../../../core/models/credito/referencia.model';
import { ApiDatosSocioService } from '../../../../core/services/credito/api.datos.socio/api.datos.socio.service';
import { TelefonoModel } from '../../../../core/models/credito/telefono.model';
import { ValidacionUtils } from '../../../../core/services/utileria/validaciones.service';
import { ParentescoModel } from '../../../../core/models/credito/parentesco.model';
import { ValidacionErrors } from 'src/app/core/services/utileria/validacion.error';
import { DialogMensajeComponent } from '../../../../shared/components/modales/modalMaterialCancelaciones.1/dialog-overview-msg1';
import { DialogReintentarComponent } from '../../../../shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { ArchivoService, UserService } from 'src/app/core';
import { ApiValidacionesService } from 'src/app/core/services/credito/api.adelanto.validaciones/api.validaciones.service';
import { ApiDocumentosService } from 'src/app/core/services/credito/api.documentos/api.documentos';
import { EstatusConsentimiento, Intentos, OpcionesIntentosAltaDocumentos, TipoDocumento } from 'src/app/core/services/utileria/utileria.enum';
import { DialogOverview } from 'src/app/shared/components/modales/modalMaterial/dialog-overview';
import { DialogOverviewMsg } from 'src/app/shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { ConsentimientoModel } from 'src/app/core/services/credito/api.consentimiento/consentimiento.model';
import { ApiConsentimientoService } from 'src/app/core/services/credito/api.consentimiento/api.consentimiento.service';

@Component({
  selector: 'app-referencias-familiares',
  templateUrl: './referencias.familiares.component.html'
})
export class ReferenciasFamiliaresComponent implements OnInit {
  public formReferencias: FormGroup;

  referenciaModel: ReferenciaModel[];
  lstParentescos: string[] = [];
  requiredControl1: FormControl;
  requiredControl2: FormControl;
  nonRequiredControl: FormControl;

  intentos: number;
  numeroEmpleado: string;
  numEmpleado: string;
  producto : number;
  intentosBuro = 0;
  validacionCrediticia;
  opcionEnvio;
  estatus;
  clausula;

  @ViewChild('formRF', { static: true }) formRF: ElementRef;
  showLabels: boolean;
  showEditar: boolean;

  constructor(
    private readonly formBuilder: FormBuilder,
    private userService: UserService,
    private readonly router: Router,
    private validacionErrors: ValidacionErrors,
    private dialog: MatDialog,
    private datossocioService: ApiDatosSocioService,
    private validacionUtils: ValidacionUtils,
    private apiValidacionesService: ApiValidacionesService,
    private apiDocumentosService: ApiDocumentosService,
    private archivoService: ArchivoService,
    private consentimientoService: ApiConsentimientoService
  ) {
    this.referenciaModel = [new ReferenciaModel(), new ReferenciaModel()];
    this.requiredControl1 = new FormControl('', [Validators.required, Validators.pattern(this.validacionUtils.patternNombre)]);
    this.requiredControl2 = new FormControl('', [Validators.required, Validators.pattern(this.validacionUtils.patternNombre)]);
    this.nonRequiredControl = new FormControl('');
    this.intentos = sessionStorage.getItem('intento') ? +sessionStorage.getItem('intento') : 1;
  }

  ngOnInit(): void {
    this.numEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    this.formReferencias = this.formBuilder.group({
      idReferencias: new FormArray([new FormControl(''), new FormControl('')]),
      nombres: new FormArray([
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.validacionUtils.patternNombre)
        ]),
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.validacionUtils.patternNombre)
        ])
      ]),
      apellidosPaternos: new FormArray([
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.validacionUtils.patternApellidos)
        ]),
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.validacionUtils.patternApellidos)
        ])
      ]),
      apellidosMaternos: new FormArray([
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.validacionUtils.patternApellidos)
        ]),
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.validacionUtils.patternApellidos)
        ])
      ]),
      idTelefonosCasa: new FormArray([new FormControl(''), new FormControl('')]),
      telefonosCasa: new FormArray([
        new FormControl('', [
          Validators.required,
          Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)
        ]),
        new FormControl('', [
          Validators.required,
          Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)
        ])
      ]),
      idTelefonosCelular: new FormArray([new FormControl(''), new FormControl('')]),
      telefonosCelular: new FormArray([
        new FormControl('', [
          Validators.required,
          Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)
        ]),
        new FormControl('', [
          Validators.required,
          Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)
        ])
      ]),
      parentescos: new FormArray([
        new FormControl('', Validators.required),
        new FormControl('', Validators.required)
      ]),
      correos: new FormArray([
        new FormControl('', [
          this.validacionUtils.validadorCorreo(this.validacionUtils.patternCorreoSalinas),
          this.validacionUtils.validadorCorreoProhibidos(this.validacionUtils.patternCorreosProhibidos),
          Validators.pattern(this.validacionUtils.patternCorreo)
        ]),
        new FormControl('', [
          this.validacionUtils.validadorCorreo(this.validacionUtils.patternCorreoSalinas),
          this.validacionUtils.validadorCorreoProhibidos(this.validacionUtils.patternCorreosProhibidos),
          Validators.pattern(this.validacionUtils.patternCorreo)
        ])
      ]),
      otros: new FormArray([new FormControl(''), new FormControl('')])
    });

    if (window.sessionStorage) {
      const idProducto = window.sessionStorage ? sessionStorage.getItem('producto_id') : '';
      if (idProducto !== null && idProducto !== undefined && idProducto.trim() !== '') {
        this.producto = Number(idProducto);
        this.susbripciones()
        this.obtenerNumeroEmpleado();
        this.consultarApis();
        this.inicioPerfilSocio();
      } else {
        setTimeout(() => {
          this.openDialogMsg('Por favor selecciona un producto para continuar', 'Ok', true);
        });

      }
    }


  }

  validaTelCompleto(): void {
    this.validaTelCasa();
    this.validaTelMovil();
  }

  validaTelCasa(): void {
    for (let i = 0; i < 2; i++) {
      if ( (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).value === '' &&
      (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).value !== '') {
        (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).clearValidators();
        (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).updateValueAndValidity();
      } else if ( (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).value === '' &&
        (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).value === '') {
          (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).setValidators(
          [Validators.required, Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)]);
          (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).updateValueAndValidity();
        } else if ( (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).value !== '' &&
        (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).value !== '') {
          (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).setValidators(
          [Validators.required, Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)]);
          (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).updateValueAndValidity();
        }
    }
  }

  validaTelMovil(): void {
    for (let i = 0; i < 2; i++) {
      if ( (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).value === '' &&
      (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).value !== '') {
        (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).clearValidators();
        (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).updateValueAndValidity();
      } else if ( (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).value === '' &&
        (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).value === '') {
          (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).setValidators(
          [Validators.required, Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)]);
          (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).updateValueAndValidity();
        } else if ( (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).value !== '' &&
        (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).value !== '') {
          (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).setValidators(
          [Validators.required, Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)]);
          (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).updateValueAndValidity();
        }
    }
  }

  private susbripciones() {
    // Subscripción a los eventos del select
    this.formReferencias.controls['parentescos'].valueChanges.subscribe(
      data => {
        const campoValidoOtro1 = (this.formReferencias.controls['otros'] as FormArray).at(0).valid;
        const campoValidoOtro2 = (this.formReferencias.controls['otros'] as FormArray).at(1).valid;

        if (data[0] === (this.lstParentescos.length - 1)) {
          (this.formReferencias.controls['otros'] as FormArray).setControl(0, this.requiredControl1);
        } else {
          if (!campoValidoOtro1) {
            (this.formReferencias.controls['otros'] as FormArray).setControl(0, this.nonRequiredControl);
          }
        }

        if (data[1] === (this.lstParentescos.length - 1)) {
          (this.formReferencias.controls['otros'] as FormArray).setControl(1, this.requiredControl2);
        } else {
          if (!campoValidoOtro2) {
            (this.formReferencias.controls['otros'] as FormArray).setControl(1, this.nonRequiredControl);
          }
        }
      }, () => {
        console.log('Error en la subscripción');
      }
    );
  }

  verificarEmail() {
    const correo1 = (this.formReferencias.controls['correos'] as FormArray).at(0);
    const correo2 = (this.formReferencias.controls['correos'] as FormArray).at(1);
    const regex = /[a-zA-Z]/g;

    if (correo1.valid && correo1.value.length > 7) {
      const correoArr = correo1.value.split('@');
      if (correoArr[0].length < 3) {
        correo1.setErrors({'incorrect': true});
      } else {
        const arrayCaracteres = correoArr[0].split('');
        // Obtenemos los caracteres de la primera palabra que están
        // presentes en todas
        const data = arrayCaracteres.filter(
          (c, i) =>
            arrayCaracteres.indexOf(c) === i // es la primera ocurrencia del carácter
            && arrayCaracteres.every(x => x.indexOf(c) >= 0)); // y está en todas
        if (data.length > 0) {
          correo1.setErrors({'incorrect': true});
          return;
        }

        // Valida que no sean solo números
        if (correoArr[0].search(regex) === -1) {
          correo1.setErrors({'incorrect': true});
          return;
        }
      }
    }

    if (correo2.valid && correo2.value.length > 7) {
      const correoArr = correo2.value.split('@');
      if (correoArr[0].length < 3) {
        correo2.setErrors({'incorrect': true});
      } else {
        const arrayCaracteres = correoArr[0].split('');
        const data = arrayCaracteres.filter(
          (c, i) =>
            arrayCaracteres.indexOf(c) === i
            && arrayCaracteres.every(x => x.indexOf(c) >= 0));
        if (data.length > 0) {
          correo2.setErrors({'incorrect': true});
          return;
        }

        // Valida que no sean solo números
        if (correoArr[0].search(regex) === -1) {
          correo2.setErrors({'incorrect': true});
          return;
        }
      }
    }
  }

  bloquearCampos(): void {
    this.formReferencias.disable();
  }

  desbloquearCampos(): void {
    this.formReferencias.enable();
  }

  mostrarBotonEditar(): void {
    this.showEditar = true;
  }

  mostrarBotonGuardar(): void {
    this.showEditar = false;
  }

  saveData(isvalid): void {
    if (this.validarFormulario(isvalid)) {
      this.guardarReferencias();
      this.bloquearCampos();
      this.mostrarBotonEditar();
    }
  }

  editar(): void {
    this.desbloquearCampos();
    this.mostrarBotonGuardar();
  }

  cancelar(): void {
    this.bloquearCampos()
    this.mostrarBotonEditar();
  }

  inicioPerfilSocio(): void {
    const pantalla = this.router.url;
    if (pantalla === '/referenciasfamiliares') {
      this.showLabels = true;
    } else {
      this.showLabels = false;
      setTimeout(() => {
        this.bloquearCampos();
      });
    }
    this.showEditar = true;
  }

  consultarApis(): any {
    this.datossocioService.getParentescos().then(
      result => {
        this.mostrarParentescos(result);

        this.datossocioService.getReferenciasDatosSocioPromise(this.numeroEmpleado).then(
          resultRef => {
             this.llenarCampos(resultRef); },
          () => {
          }
        ).catch();
      }
    ).catch((error) => {
      this.reintentarEnvio(1, error);

    });
  }

  mostrarParentescos(parentescos: ParentescoModel[]) {
    parentescos.forEach(parenteco => {
      this.lstParentescos.push(parenteco.parentesco);
    });
  }

  private llenarCampos(result) {
    for (let i = 0; i < 2; i++) {
      const referencia = result.referencias[i];
      (this.formReferencias.controls['idReferencias'] as FormArray).at(i).setValue(referencia.idReferencia);

      (this.formReferencias.controls['nombres'] as FormArray).at(i).setValue(
        referencia.nombre === 'null' ? '' : referencia.nombre);
      (this.formReferencias.controls['apellidosPaternos'] as FormArray).at(i).setValue(
        referencia.apellidoPaterno === 'null' ? '' : referencia.apellidoPaterno);
      (this.formReferencias.controls['apellidosMaternos'] as FormArray).at(i).setValue(
        referencia.apellidoMaterno === 'null' ? '' : referencia.apellidoMaterno);
      (this.formReferencias.controls['correos'] as FormArray).at(i).setValue(
        referencia.correoElectronico === null  ? '' : referencia.correoElectronico);

      this.llenarTelefonos(i, referencia);
      this.llenarParentesco(i, referencia);
    }
  }

  llenarTelefonos(i: number, referencia: ReferenciaModel): any {
    referencia.telefonos.forEach(telefono => {
        if (telefono.idTipo === 1) {
          (this.formReferencias.controls['idTelefonosCelular'] as FormArray).at(i).setValue(telefono.idTelefono);
          (this.formReferencias.controls['telefonosCelular'] as FormArray).at(i).setValue(telefono.telefono);
        } else if (telefono.idTipo === 2) {
          (this.formReferencias.controls['idTelefonosCasa'] as FormArray).at(i).setValue(telefono.idTelefono);
          (this.formReferencias.controls['telefonosCasa'] as FormArray).at(i).setValue(telefono.telefono);
        }
    });
    this.validaTelCompleto();

  }

  llenarParentesco(i: number, referencia: ReferenciaModel): any {
    (this.formReferencias.controls['parentescos'] as FormArray).at(i).setValue((referencia.idParentesco - 1));
    if (referencia.idParentesco === this.lstParentescos.length) {
      (this.formReferencias.controls['otros'] as FormArray).at(i).setValue(referencia.otroParentesco);
    }
  }

  routeFunction(ruta: string) {
    this.router.navigate([ruta], { skipLocationChange: false });
  }

  guardarReferencias() {
    // TODO:API Guardar referencias
    this.datossocioService.postReferenciasDatosSocio(this.numeroEmpleado,
      this.recuperarDatosFormulario(this.numeroEmpleado)).then(() => {
        if (this.router.url === '/referenciasfamiliares') {
          this.continuarSigPantalla();
        } else {
          this.consultarApis();
        }
      }, (error) => {
          console.log('No fue posible guardar, reintentando: ' + JSON.stringify(error));
          this.reintentarEnvio(2, error);
        }
      )
    .catch();

  }

  recuperarDatosFormulario(numeroEmpleado) {
    let i = 0;
    this.referenciaModel.forEach(referencia => {
      const formReferenciasValue = this.formReferencias.value;

      referencia.idReferencia = formReferenciasValue.idReferencias[i] !== '' ? formReferenciasValue.idReferencias[i] : null;
      referencia.nombre = formReferenciasValue.nombres[i];
      referencia.apellidoPaterno = formReferenciasValue.apellidosPaternos[i];
      referencia.apellidoMaterno = formReferenciasValue.apellidosMaternos[i];
      referencia.correoElectronico = formReferenciasValue.correos[i] !== '' ? formReferenciasValue.correos[i] : null;;
      referencia.idParentesco = formReferenciasValue.parentescos[i] + 1;
      referencia.otroParentesco = this.obtenerParentesco(formReferenciasValue.parentescos[i], i);
      referencia.telefonos = this.obtenerTelefonos(formReferenciasValue, i);
      i++;
    });

    const requestRefModel = new RequestReferenciasModel();
    requestRefModel.numeroEmpleado = numeroEmpleado;
    requestRefModel.referencias = this.referenciaModel;
    return requestRefModel;
  }

  obtenerTelefonos(formReferenciasValue, i): TelefonoModel[] {
    let idTelCasa = formReferenciasValue.idTelefonosCasa[i];
    let idTelCelular = formReferenciasValue.idTelefonosCelular[i];
    idTelCasa = idTelCasa === '' ? null : +idTelCasa;
    idTelCelular = idTelCelular === '' ? null : +idTelCelular;

    if (formReferenciasValue.telefonosCasa[i] !== '' && formReferenciasValue.telefonosCelular[i] !== '') {
      return [
        new TelefonoModel(idTelCelular, 1, 'Movil', formReferenciasValue.telefonosCelular[i], ''),
        new TelefonoModel(idTelCasa, 2, 'Casa', formReferenciasValue.telefonosCasa[i], '')
      ];
    }

    if (idTelCasa !== '' && idTelCelular !== '' && idTelCasa !== null && idTelCelular !== null) {
      return [
        new TelefonoModel(idTelCelular, 1, 'Movil', formReferenciasValue.telefonosCelular[i], ''),
        new TelefonoModel(idTelCasa, 2, 'Casa', formReferenciasValue.telefonosCasa[i], '')
      ];
    }

    if (formReferenciasValue.telefonosCasa[i] !== '') {
      return [
        new TelefonoModel(idTelCasa, 2, 'Casa', formReferenciasValue.telefonosCasa[i], '')
      ];
    }

    if (formReferenciasValue.telefonosCelular[i] !== '') {
      return [
        new TelefonoModel(idTelCelular, 1, 'Movil', formReferenciasValue.telefonosCelular[i], '')
      ];
    }

    return [];
  }

  // Dado que el campo parentescos se basa en el ID
  // obtendremos el valor del campo seleccionado.
  private obtenerParentesco(idParentesco: number, i: number): string {
    if (idParentesco === (this.lstParentescos.length - 1)) {
      return this.formReferencias.value.otros[i];
    }
    return this.lstParentescos[idParentesco];
  }

  continuarSigPantalla() {
    if(this.producto === 2){
      this.validarMostrarModal();
    }else{
      this.router.navigateByUrl('/configurador', {
        skipLocationChange: false
      }).catch(e => {
        if (e.message.includes('URL')) {
          this.openDialogMsg('Ocurrió un error al conectar con los servicios, por favor verifique su conexión a internet.', 'Ok', false);
          return;
        }
        this.openDialogMsg('Ocurrió un error al conectar con los servicios, por favor intente más tarde.', 'Ok', false);
      });
    }

  }

  validarFormulario(isValid): any {
    if (isValid) {
      const nombres = !this.formReferencias.controls['nombres'].valid;
      const apellidosPaternos = !this.formReferencias.controls['apellidosPaternos'].valid;
      const apellidosMaternos = !this.formReferencias.controls['apellidosMaternos'].valid;
      const otros = !this.formReferencias.controls['otros'].valid;
      const parentescos = !this.formReferencias.controls['parentescos'].valid;
      const correos = !this.formReferencias.controls['correos'].valid;
      const telCasa1 = (this.formReferencias.controls['telefonosCasa'] as FormArray).at(0);
      const telCasa2 = (this.formReferencias.controls['telefonosCasa'] as FormArray).at(1);
      const telCel1 = (this.formReferencias.controls['telefonosCelular'] as FormArray).at(0);
      const telCel2 = (this.formReferencias.controls['telefonosCelular'] as FormArray).at(1);

      if (nombres || apellidosPaternos || apellidosMaternos
        || (telCasa1.value === '' && telCel1.value === '') || (telCasa2.value === '' && telCel2.value === '')) {
        this.openDialogMsg('Por favor registra el nombre de las dos referencias con al menos un teléfono para cada uno.', 'Ok', false);
        return false;
      }

      if ((telCasa1.value !== '' && (telCasa1.value === telCasa2.value || telCasa1.value === telCel2.value))
        || (telCel1.value !== '' && (telCel1.value === telCel2.value || telCel1.value === telCasa2.value))) {
        this.openDialogMsg('El teléfono no debe ser el mismo para ambas referencias', 'Ok', false);
        return false;
      }

      if ((telCasa1. value === telCel1.value) && (telCasa1.value !== '' && telCel1.value !== '')) {
        this.openDialogMsg('El teléfono de casa no puede ser el mismo al teléfono móvil, verifica la referencia. 1', 'Ok', false);
        return false;
      }

      if ((telCasa2. value === telCel2.value) && (telCasa2.value !== '' && telCel2.value !== '')) {
        this.openDialogMsg('El teléfono de casa no puede ser el mismo al teléfono móvil, verifica la referencia. 2', 'Ok', false);
        return false;
      }

      if (parentescos) {
        this.openDialogMsg('Verifica que todos los campos tengan la información completa', 'Ok', false);
        return false;
      }

      if (correos) {
        this.openDialogMsg('Verifica que todos los campos sean correctos', 'Ok', false);
        return false;
      }

      if (otros) {
        this.openDialogMsg('Especifica el tipo de parentesco.', 'Ok', false);
        return false;
      }

      return true;

    } else {
      this.openDialogMsg('Verifica que todos los campos hayan sido capturados correctamente y de forma completa.', 'Ok', false);
      return false;
    }

  }

  private reintentarEnvio(api: number, error: any): void {
    if (error.status === 500) {
      if (this.intentos < 3 ) {
        this.intentos++;
        sessionStorage.setItem('intento', this.intentos.toString());
        this.dialog.closeAll();
        this.openDialogMsg('Ocurrió un error al conectar con los servicios.', 'Reintentar', false, true, api);
      } else {
        sessionStorage.removeItem('intento');
        this.dialog.closeAll();
        this.openDialogMsg('Ocurrió un error al conectar con los servicios. Por favor intente más tarde.', 'Ok', true);
      }
    } else {
      this.dialog.closeAll();
      this.openDialogMsg(this.validacionErrors.getError(error), 'Ok', true);
    }
  }

  private obtenerNumeroEmpleado() {
    this.numeroEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
  }

  private openDialogMsg(msg: string, boton: string, route: boolean, intent?: boolean, api?: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
      if (intent) {
        switch (api) {
          case 1: this.consultarApis(); break;
          case 2: this.guardarReferencias(); break;
          default: console.log('api no valida para reenvio');
        }
      }
    });
  }

  openDialogCancelacionesMensaje(): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        message: '¿Estás seguro que deseas salir? No se guardará el avance.',
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
    });

  }
  validarMostrarModal(): void {
    this.apiValidacionesService.getValidacionesBuroPrestamoPersonal([5, 100], this.numEmpleado).subscribe((response) => {
      this.intentos = 1;
     this.abrirModal(response.resultado.requerido);
    }, (error) => {
      this.manejoErrorConexion(error, OpcionesIntentosAltaDocumentos.VALIDA_MODAL);
    });


  }

  abrirModal(validacionCrediticia): void {
    if (validacionCrediticia === true) {
      this.apiDocumentosService.getObtenerDocumentos(this.numEmpleado, ['' +
        TipoDocumento.AUTORIZACION_DE_CONSULTA_A_BURO]).subscribe((response) => {
          this.intentos = 1;
          const documentos = response.resultado.documentos;
          // Metodo para recuperar los archivos de BD CON
          for (let i = 0; i < documentos.length; i++) {
            if (this.archivoService.validarTipoImagen(documentos[i].extension)) {
              const nombre = documentos[i].nombre + '.' + documentos[i].extension;
              // setear el valor del tipo de contenido
              const contentType = this.archivoService.validarTipoImagen(documentos[i].extension);
              // Obtener el base 64
              const base64 = documentos[i].base64;
              // Convertir it to a blob to upload
              const blob = this.archivoService.b64toBlob(base64, contentType, null);
                const dialogRef = this.dialog.open(DialogOverview, {
                  width: '750px',
                  height: '95vh',
                  data: {
                    titulo: 'Investigación Crediticia',
                    urlPdf: blob,
                    showDownloadButton: false,
                    disableClose: true
                  },
                  autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result) {
                    this.intentosBuro++;

                    this.openDialogMsgBuro('Recuerda que es necesario contar con tu autorización para realizar la investigación' +
                    ' crediticia para continuar con tu solicitud', true);

                  } else {

                    this.guardarConsentimientos(2, EstatusConsentimiento.ESTATUS_APROBADO, TipoDocumento.AUTORIZACION_DE_CONSULTA_A_BURO);
                  }
                });

            }
          }
        }, (error) => {
          this.validacionCrediticia = validacionCrediticia;
          this.manejoErrorConexion(error, OpcionesIntentosAltaDocumentos.ABRIR_MODAL);
        });

    } else {
      this.router.navigateByUrl('/configurador', {
        skipLocationChange: false
      }).catch(e => {
        if (e.message.includes('URL')) {
          this.openDialogMsg('Ocurrió un error al conectar con los servicios, por favor verifique su conexión a internet.', 'Ok', false);
          return;
        }
        this.openDialogMsg('Ocurrió un error al conectar con los servicios, por favor intente más tarde.', 'Ok', false);
      });
    }
  }
  openDialogMsgBuro(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route && this.intentosBuro === 2) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      } else if (route && this.intentosBuro < 2) {
        this.validarMostrarModal();
      }
    });
  }

  manejoErrorConexion(error, opcion: number) {
    if (error.status === 400 || (error.status > 402 && error.status < 500)) {
      this.dialog.closeAll();
      this.openDialogReintentar(this.validacionErrors.getError(error), 'OK', false, opcion);
    } else if (error.status === 500 && this.intentos < Intentos.MAX_INTENTOS) {
      this.intentos++;
      this.dialog.closeAll();

      this.openDialogReintentar('Ocurrio un problema de conexión. Intentalo nuevamente', 'Reintentar', true, opcion);
    } else if (error.status === 500 && this.intentos >= Intentos.MAX_INTENTOS) {
      this.dialog.closeAll();
      this.openDialogReintentar('Ocurrio un error al conectar con los servicios. Por favor inténtalo más tarde', 'OK', false, opcion);
      this.router.navigate(['/home'], { skipLocationChange: false });
      this.intentos = 1;
    }
  }

  openDialogReintentar(msg: string, boton: string, reintento: boolean, opcion: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      if (reintento) {
        switch (opcion) {
          case 1: this.guardarConsentimientos(this.opcionEnvio, this.estatus, this.clausula); break;
          default: console.log('opcion no valida');
          case 2: this.validarMostrarModal(); break;
          case 3: this.abrirModal(this.validacionCrediticia); break;
        }

      }
    });
  }

  guardarConsentimientos(opcionEnvio: number, estatus: number, clausula: number) {

    const request = new ConsentimientoModel();
    request.numeroEmpleado = this.numEmpleado;
    request.idClausula = clausula;
    request.idProducto = 1;
    request.estatus = estatus;
      this.consentimientoService.postConsentimiento(request).subscribe(response => {
        this.intentos = 1;
        this.router.navigateByUrl('/configurador', {
          skipLocationChange: false
        }).catch(e => {
          if (e.message.includes('URL')) {
            this.openDialogMsg('Ocurrió un error al conectar con los servicios, por favor verifique su conexión a internet.', 'Ok', false);
            return;
          }
          this.openDialogMsg('Ocurrió un error al conectar con los servicios, por favor intente más tarde.', 'Ok', false);
        });


      }, (error) => {
        this.opcionEnvio = opcionEnvio;
        this.estatus = estatus;
        this.clausula = clausula;
        this.manejoErrorConexion(error, 1);
      });


  }

}
