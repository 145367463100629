import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../../core/services/auth/auth-guard.service';
import { ReferenciasFamiliaresComponent } from './referencias.familiares.component';

const routes: Routes = [
  {
    path: 'referenciasfamiliares',
    component: ReferenciasFamiliaresComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReferenciasFamiliaresModule {}
