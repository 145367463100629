import { Component, Input, OnInit } from '@angular/core';
import { HomeComponent } from '../../home.component';

@Component({
  selector: 'app-aducacion-financiera',
  templateUrl: './aducacion-financiera.component.html',
  styleUrls: ['./aducacion-financiera.component.scss']
})
export class AducacionFinancieraComponent implements OnInit {
parentHome: HomeComponent;
modalVideoIntro = false;
modalVideoHerramientas = false;
  @Input() correo ='beneficiosfinancierosgs@gruposalinas.com.mx';
  @Input() videoIntro ;
  @Input() activoli;
  constructor( parentHome: HomeComponent) {
    this.parentHome = parentHome;
     }

  ngOnInit(): void {
  }

  mostrarVideo() {
    this.modalVideoIntro = true;
  }

  cerrarVideo() {
    this.modalVideoIntro = false;
  }

  mostrarVideo2() {
    this.modalVideoHerramientas = true;
  }

  cerrarVideo2() {
    this.modalVideoHerramientas = false;
  }
}
