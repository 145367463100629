import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../../shared/components/spinnner/spinner.service';
import { ApiDatosSocioService } from '../../../../core/services/credito/api.datos.socio/api.datos.socio.service';
import { ApiZonificacionService } from '../../../../core/services/credito/api.zonificacion/api.zonificacion.service';
import { DatosSocioModel } from 'src/app/core/models/credito/datos.socios/datos.socio.model';
import { MatDialog } from '@angular/material/dialog';
import { ValidacionUtils } from '../../../../core/services/utileria/validaciones.service';
import { DialogOverviewMsg } from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { MapaGoogleComponent } from 'src/app/shared/components/mapaGoogle/mapa.component';
import { ApiDocumentosService } from 'src/app/core/services/credito/api.documentos/api.documentos';
import { UtileriaEnum, TipoTelefono, Paises } from 'src/app/core/services/utileria/utileria.enum';
import { DialogMensajeComponent } from '../../../../shared/components/modales/modalMaterialCancelaciones.1/dialog-overview-msg1';
import { DialogReintentarComponent } from 'src/app/shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { TelefonoModel } from 'src/app/core/models/credito/telefono.model';
import { DireccionModel } from 'src/app/core/models/credito/direccion.model';
import { ValidacionErrors } from 'src/app/core/services/utileria/validacion.error';
import { UserService } from '../../../../core/services/user.service';
import { HomeService } from '../../../../core/services/home.service';
import { DialogValidacionComponent } from '../../../../shared/components/modales/modalMaterial.1/dialog-overview';


@Component({
  selector: 'app-datos-socio',
  templateUrl: './datos.socio.component.html'
})
export class DatosSocioComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MapaGoogleComponent, { static: true }) mapaGoogle: MapaGoogleComponent;

  public formDatosSocio: FormGroup;

  private msjSpinner = 'En este momento estás entrando en contacto con Banco Azteca S.A. Institución de Banca Múltiple.';

  @ViewChild('formDS') formDS: ElementRef;

  numEmpleado: string;
  idProducto: number;
  idDocumentos: string[];
  calles = ['calle_adelante', 'calle_atras', 'calle_derecha', 'calle_izquierda'];

  // Declaración de variables
  datosSocio: any;
  listaColonias: any;
  responseInfoEmpleado: any;
  direccionEmpleado: any;
  intentos: number;
  pantalla: string;
  resInformacionCodigoPostal: any;
  aside;
  colonia: any;
  tipoDomicilioClienteDesc: any;
  tipoDomicilioCliente: any;
  antiguedadResidencia: any;
  mostraBtnRecargar = false;
  colorCalle = false;

  // TODO: construir despues de obtner información del Socio
  direccionMapa = 'Adolfo Lopez Mateos, Mavoro';
  showLabels: boolean;
  showEditar: boolean;
  // Constructor
  constructor(
    private formBuilder: FormBuilder,
    private spinnerSrv: SpinnerService,
    private router: Router,
    private datossocioService: ApiDatosSocioService,
    private apiDocumentos: ApiDocumentosService,
    private dialog: MatDialog,
    private homeService: HomeService,
    private zonificacionService: ApiZonificacionService,
    private validacionErrors: ValidacionErrors,
    private validacionUtils: ValidacionUtils,
    private userService: UserService) {
    this.intentos = sessionStorage.getItem('intento') ? +sessionStorage.getItem('intento') : 0;
  }

  // Inicio para variables
  ngOnInit(): void {
    this.pantalla = this.router.url;
    this.intentos = 1;
    this.datosSocio = this.formBuilder.group({
      telefono: ['', Validators.compose(
        [
          Validators.required,
          Validators.minLength(10),
          this.validacionUtils.validatorTelefono(this.validacionUtils.patternTelGrupo),
          Validators.pattern(this.validacionUtils.patternTelConsec),
          Validators.pattern(this.validacionUtils.patternNumRep)
        ]
      )],
      correo: ['', Validators.compose(
        [
          Validators.required,
          this.validacionUtils.validadorCorreo(this.validacionUtils.patternCorreoSalinas),
          this.validacionUtils.validadorCorreoProhibidos(this.validacionUtils.patternCorreosProhibidos),
          Validators.pattern(this.validacionUtils.patternCorreo)
        ]
      )],
      calle: ['', Validators.compose(
        [
          Validators.required,
          Validators.pattern(this.validacionUtils.patternCalles)])],
      numero_exterior: ['', Validators.compose(
        [Validators.required, Validators.pattern(this.validacionUtils.patternCadenaValidaNum)]
      )],
      numero_interior: ['', Validators.compose(
        [Validators.pattern(this.validacionUtils.patternCadenaValidaNum)]
      )],
      calle_adelante: ['', Validators.compose(
        [Validators.pattern(this.validacionUtils.patternCalles)]
      )],
      calle_atras: ['', Validators.compose(
        [Validators.pattern(this.validacionUtils.patternCalles)]
      )],
      calle_derecha: ['', Validators.compose(
        [Validators.pattern(this.validacionUtils.patternCalles)]
      )],
      calle_izquierda: ['', Validators.compose(
        [Validators.pattern(this.validacionUtils.patternCalles)]
      )],
      codigo_postal: ['', Validators.required],
      colonia: ['', Validators.compose(
        [Validators.required, Validators.pattern(this.validacionUtils.patternCadenaValidaColonias)]
      )],
      idColonia: [],
      alcaldia_municipio: ['', Validators.compose(
        [Validators.required, Validators.pattern(this.validacionUtils.patternCalles)]
      )],
      idDelegacionMunicipio: [],
      estado: ['', Validators.compose(
        [Validators.required, Validators.pattern(this.validacionUtils.patternCalles)]
      )],
      idEstado: [],
      referencia_domicilio: ['', Validators.compose(
        [Validators.required, Validators.pattern(this.validacionUtils.patternReferencia)]
      )],
      idTipoDireccion: [],
      tipoDomicilioClienteDesc: ['', Validators.compose(
        [Validators.required, Validators.pattern(this.validacionUtils.patternCadenaValida)]
      )],
      idAntiguedadResidencia: [],
      antiguedadResidenciaDesc: ['', Validators.compose(
        [Validators.required, Validators.pattern(this.validacionUtils.patternCadenaValida)]
      )],
    });

    this.getInformacionBasica();
    if (this.pantalla === '/datossocio') {
      this.showLabels = true;
    } else {
      this.showLabels = false;
    }
    this.getDatosSocio();
    this.showEditar = true;

  }

  verificarEmail() {
    const correo = this.datosSocio.controls['correo'];

    if (correo.valid && correo.value.length > 7) {
      const correoArr = correo.value.split('@');
      if (correoArr[0].length < 3) {
        correo.setErrors({ 'incorrect': true });
      } else {
        const arrayCaracteres = correoArr[0].split('');
        const data = arrayCaracteres.filter(
          (c, i) =>
            arrayCaracteres.indexOf(c) === i
            && arrayCaracteres.every(x => x.indexOf(c) >= 0));
        if (data.length > 0) {
          correo.setErrors({ 'incorrect': true });
          return;
        }

        // Valida que no sean solo números
        const regex = /[a-zA-Z]/g;
        if (correoArr[0].search(regex) === -1) {
          correo.setErrors({ 'incorrect': true });
          return;
        }
      }
    }
  }

  getDatosSocio() {
    this.datossocioService.getDatosSocio(
      this.numEmpleado).subscribe(respuesta => {
        this.getComboTipoDomicilioCliente();
        this.setInformacionEmpleado(respuesta.resultado);
      }, (err) => {
        this.reintentarConectar(err, 1);
      });
  }

  // Probar API con permisos de conexión y cambiar id de documento
  getAvisoDePrivacidadPDF(): void {
    this.idDocumentos.push('1');
    this.apiDocumentos.getObtenerDocumentos(this.numEmpleado, this.idDocumentos);
  }

  getInformacionBasica() {
    this.numEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    const idProducto = window.sessionStorage ? sessionStorage.getItem('producto_id') : '';
    if (this.pantalla === '/datossocio') {
      if (idProducto !== null && idProducto !== undefined && idProducto.trim() !== '') {
        this.idProducto = +idProducto;
      } else {
        setTimeout(() => {
          this.openDialogMsgSocios('Por favor selecciona un producto para continuar', 'OK', false, 0);
        });
      }
    }
  }

  ngOnDestroy() {
    this.spinnerSrv.cerrarSpinnerOndestroy();
  }

  validarComponenteMapaCreado() {
    if (this.mapaGoogle.mapaCargadoCorrectamente) {
      this.mostraBtnRecargar = false;
      return true;
    } else {
      this.mostraBtnRecargar = true;
    }
  }

  recargarMapa() {
    this.mapaGoogle.mapLoader.load().then(() => {
      this.mapaGoogle.geocoder = new google.maps.Geocoder();
      this.mapaGoogle.cargarMapa();
      this.mapaGoogle.mapaCargadoCorrectamente = true;
      this.actualizarCoordenadas();
    }).catch(error => {
    });
  }

  actualizarCoordenadas() {

    const lat = this.direccionEmpleado.latitud;

    const lng = this.direccionEmpleado.longitud;



    this.validarComponenteMapaCreado();

    setTimeout(() => {

      if ((lat != null && lng != null) || (lat === '19.432602' && lng === '-99.133205')) {

        const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };

        this.mapaGoogle.geocodeAddressUpdateCoor(latlng, this.direccionEmpleado.calle, this.direccionEmpleado.colonia,

          this.direccionEmpleado.delegacionMunicipio, this.direccionEmpleado.estado);

      } else {

        this.mapaGoogle.geocodeAddressUpdate(this.direccionMapa, this.direccionEmpleado.calle, this.direccionEmpleado.colonia,

          this.direccionEmpleado.delegacionMunicipio, this.direccionEmpleado.estado);

      }

    }, 1000);

  }

  actualizarDomicilio() {
    // tslint:disable-next-line: max-line-length
    const numExterior = this.datosSocio.get('numero_exterior').value != null && this.datosSocio.get('numero_exterior').value !== undefined ? this.datosSocio.get('numero_exterior').value : '';
    const colonia = this.datosSocio.get('colonia').value;
    const calle = this.datosSocio.get('calle').value;
    const edo = this.datosSocio.get('estado').value;
    const municipio = this.datosSocio.get('alcaldia_municipio').value;
    this.direccionMapa = calle + ' ' + numExterior + ', ' + colonia + ', ' + municipio + ', ' + edo;

    if (this.validarComponenteMapaCreado()) {
      this.mapaGoogle.geocodeAddressUpdate(this.direccionMapa, calle, colonia, municipio, edo);
    }

    // Obtener idEstado y IdColonia cuando el cliente inicia por primera vez.
    if (this.resInformacionCodigoPostal !== undefined && this.resInformacionCodigoPostal != null) {
      this.resInformacionCodigoPostal.colonias.forEach(value => {
        if (String(value.nombre.toLowerCase()) === String(colonia.toLowerCase())) {
          this.responseInfoEmpleado.idDelegacionMunicipio = this.resInformacionCodigoPostal.idMunicipio;
          this.responseInfoEmpleado.idEstado = this.resInformacionCodigoPostal.idEstado;
        }
      });
    }
  }

  public obternerDatosPorCP() {
    const idPais = 1;
    this.datosSocio.get('colonia').setValue('');
    this.datosSocio.get('alcaldia_municipio').setValue('');
    this.datosSocio.get('estado').setValue('');
    const codigoPostal = this.datosSocio.get('codigo_postal').value;
    if (codigoPostal != null && codigoPostal.length === 5) {
      this.zonificacionService.getInformacionCodigoPostal(
        codigoPostal).subscribe(informacionCP => {
          this.resInformacionCodigoPostal = informacionCP;
          this.datosSocio.get('alcaldia_municipio').setValue(informacionCP.municipio);
          this.datosSocio.get('estado').setValue(informacionCP.estado);
          this.actualizarMapa();
        }, (err) => {
          this.listaColonias = [];
          this.datosSocio.get('codigo_postal').setValue('');
          this.reintentarConectar(err, 3);
        });
    } else {
      this.listaColonias = [];
      this.datosSocio.get('codigo_postal').setValue('');
    }
  }

  obtenerListaColonias(codigoPostal) {
    this.zonificacionService.getInformacionCodigoPostal(codigoPostal).subscribe(informacionCP => {
      this.resInformacionCodigoPostal = informacionCP;
      this.listaColonias = this.resInformacionCodigoPostal.colonias;
      }, (err) => {
        this.listaColonias = [];
        this.datosSocio.get('codigo_postal').setValue('');
        this.reintentarConectar(err, 3);
      });
  }

  actualizarMapa() {
    const direccion = this.resInformacionCodigoPostal;
    const calle = this.datosSocio.get('calle').value;
    const municipio = this.datosSocio.get('alcaldia_municipio').value;

    // tslint:disable-next-line: max-line-length
    const numExterior = this.datosSocio.get('numero_exterior').value != null &&
      this.datosSocio.get('numero_exterior').value !== undefined ? this.datosSocio.get('numero_exterior').value : '';
    // tslint:disable-next-line: max-line-length
    this.direccionMapa = calle + ' ' + numExterior + ', ' + direccion.colonias[0].nombre + ', ' + direccion.municipio + ', ' + direccion.estado;
    this.responseInfoEmpleado.idDelegacionMunicipio = direccion.idMunicipio;
    this.responseInfoEmpleado.idEstado = direccion.idEstado;
    this.listaColonias = this.resInformacionCodigoPostal.colonias;
    this.datosSocio.get('colonia').setValue(direccion.colonias[0].nombre);
    this.datosSocio.get('alcaldia_municipio').setValue(direccion.municipio);
    this.datosSocio.get('estado').setValue(direccion.estado);
    if (this.validarComponenteMapaCreado()) {
      this.mapaGoogle.geocodeAddressUpdate(this.direccionMapa, calle, direccion.colonias[0].nombre, direccion.municipio, direccion.estado);
    }
  }

  setInformacionEmpleado(data: DatosSocioModel) {
    this.responseInfoEmpleado = data;
    const telefono = this.getTelefonos(data.telefonos[0]);
    this.direccionEmpleado = this.getDireccion(data.direcciones[0]);
    this.datosSocio.get('telefono').setValue(telefono.telefono);
    this.datosSocio.get('correo').setValue(data.correoElectronico);
    this.datosSocio.get('calle').setValue(this.direccionEmpleado.calle);
    this.datosSocio.get('numero_exterior').setValue(this.direccionEmpleado.numExterior);
    this.datosSocio.get('numero_interior').setValue(this.direccionEmpleado.numInterior);
    this.datosSocio.get('calle_adelante').setValue(this.direccionEmpleado.calleAdelante !== null
      ? this.direccionEmpleado.calleAdelante : 'XXXX');
    this.datosSocio.get('calle_atras').setValue(this.direccionEmpleado.calleAtras !== null ? this.direccionEmpleado.calleAtras : 'XXXX');
    this.datosSocio.get('calle_derecha').setValue(this.direccionEmpleado.calleDerecha !== null
      ? this.direccionEmpleado.calleDerecha : 'XXXX');
    this.datosSocio.get('calle_izquierda').setValue(this.direccionEmpleado.calleIzquierda !== null
      ? this.direccionEmpleado.calleIzquierda : 'XXXX');
    this.datosSocio.get('codigo_postal').setValue(this.direccionEmpleado.codigoPostal);
    this.obtenerListaColonias(this.direccionEmpleado.codigoPostal);
    this.datosSocio.get('colonia').setValue(this.direccionEmpleado.colonia);
    this.datosSocio.get('alcaldia_municipio').setValue(this.direccionEmpleado.delegacionMunicipio);
    this.datosSocio.get('estado').setValue(this.direccionEmpleado.estado);
    this.datosSocio.get('referencia_domicilio').setValue(this.direccionEmpleado.referenciaDomicilio);
    this.responseInfoEmpleado.idDelegacionMunicipio = this.direccionEmpleado.idDelegacionMunicipio;
    this.responseInfoEmpleado.idEstado = this.direccionEmpleado.idEstado;
    this.responseInfoEmpleado.idTipoDireccion = this.direccionEmpleado.idTipoDireccion;
    this.responseInfoEmpleado.idAntiguedadResidencia = this.direccionEmpleado.idAntiguedadResidencia;

    this.modifcarDireccionPerfil(telefono.telefono);

    // Mostrar información en perfil socio.
    let dirEmpleado = this.direccionEmpleado.calle + ' #';
    dirEmpleado += this.direccionEmpleado.numExterior + ', ';
    dirEmpleado += this.direccionEmpleado.colonia + ', ';
    dirEmpleado += this.direccionEmpleado.estado + ', ';
    dirEmpleado += this.direccionEmpleado.delegacionMunicipio + ', ';
    dirEmpleado += 'C.P. ' + this.direccionEmpleado.codigoPostal + '.';
    sessionStorage.setItem('telefonoEmpleado', telefono.telefono);
    sessionStorage.setItem('antiguedad', data.fechaIngreso);
    sessionStorage.setItem('direccionEmpleado', dirEmpleado);
    this.direccionMapa = this.direccionEmpleado.calle + ' ' + this.direccionEmpleado.numExterior
      + this.direccionEmpleado.colonia + ', ' + this.direccionEmpleado.delegacionMunicipio
      + this.direccionEmpleado.estado;

    this.actualizarCoordenadas();
    if (this.pantalla === '/datossocio') {
      this.spinnerSrv.abrirSpinner(this.msjSpinner);
    }
  }

  getComboTipoDomicilioCliente() {
    this.datossocioService.getTipoDirecciones().subscribe(respuesta => {
      this.tipoDomicilioCliente = respuesta.resultado;

      this.tipoDomicilioCliente.forEach(element => {
        if (this.responseInfoEmpleado.idTipoDireccion === element.idTipoDireccion) {
          this.datosSocio.get('tipoDomicilioClienteDesc').setValue(element.tipoDireccion);
          return;
        }
      });
    }, error => {
      this.reintentarConectar(error, 4);
    });

    this.getComboAntiguedadResidencias();
  }

  getComboAntiguedadResidencias() {

    this.datossocioService.getAntiguedadResidencias().subscribe(respuesta => {
      this.antiguedadResidencia = respuesta.resultado;
      this.antiguedadResidencia.forEach(element => {
        if (this.responseInfoEmpleado.idAntiguedadResidencia === element.idAntiguedadResidencia) {
          this.datosSocio.get('antiguedadResidenciaDesc').setValue(element.antiguedadResidencia);
          return;
        }
      });
    }, error => {
      this.reintentarConectar(error, 5);
    });
  }

  cambiarValorTipoDomicilio(opcion) {
    this.tipoDomicilioCliente.forEach(value => {
      if (opcion === value.tipoDireccion) {
        this.responseInfoEmpleado.idTipoDireccion = value.idTipoDireccion;
        return;
      }
    });
  }

  cambiarValorAntiguedadResidencias(opcion) {
    this.antiguedadResidencia.forEach(value => {
      if (opcion === value.antiguedadResidencia) {
        this.responseInfoEmpleado.idAntiguedadResidencia = value.idAntiguedadResidencia;
        return;
      }
    });
  }

  getTelefonos(telefono) {
    if (telefono != null) {
      return telefono;
    } else {
      telefono = new TelefonoModel(0, 1, '', '', '');
      telefono.idTelefono = null;
      telefono.idTipo = 1;
      telefono.telefono = '';
      telefono.extension = '';
      telefono.tipo = TipoTelefono.TIPO_MOVIL;
      this.responseInfoEmpleado.telefonos = [telefono];
      return telefono;
    }
  }

  getDireccion(direccion) {
    if (direccion != null) {
      return direccion;
    } else {
      direccion = new DireccionModel();
      direccion.idDireccion = null;
      direccion.calle = '';
      direccion.idColonia = '';
      direccion.colonia = '';
      direccion.idDelegacionMunicipio = '';
      direccion.delegacionMunicipio = '';
      direccion.idEstado = '';
      direccion.estado = '';
      direccion.codigoPostal = '';
      direccion.numExterior = '';
      direccion.numInterior = '';
      direccion.referenciaDomicilio = '';
      direccion.numTiendaCercana = '';
      direccion.latitud = '';
      direccion.longitud = '';
      direccion.calleAdelante = '';
      direccion.calleAtras = '';
      direccion.calleDerecha = '';
      direccion.calleIzquierda = '';

      return direccion;
    }
  }

  validarCalles1() {
    let cont = 0;
    this.calles.forEach((calle) => {
      if (this.datosSocio.get(calle).value === '') {
        cont++;
      }
    });
    if (cont > 2) {
      this.colorCalle = true;

    } else {
      this.colorCalle = false;
    }
    return this.colorCalle;
  }

  guardarInformacion() {
    const telefono = this.responseInfoEmpleado.telefonos[0];
    telefono.telefono = this.datosSocio.get('telefono').value;
    telefono.tipo = TipoTelefono.TIPO_MOVIL;
    const telefonoModel = [telefono];
    this.direccionEmpleado.calle = this.datosSocio.get('calle').value;
    this.direccionEmpleado.colonia = this.datosSocio.get('colonia').value;
    this.direccionEmpleado.idDelegacionMunicipio = this.responseInfoEmpleado.idDelegacionMunicipio;
    this.direccionEmpleado.delegacionMunicipio = this.datosSocio.get('alcaldia_municipio').value;
    this.direccionEmpleado.idEstado = this.responseInfoEmpleado.idEstado;
    this.direccionEmpleado.idTipoDireccion = this.responseInfoEmpleado.idTipoDireccion;
    this.direccionEmpleado.idAntiguedadResidencia = this.responseInfoEmpleado.idAntiguedadResidencia;
    this.direccionEmpleado.estado = this.datosSocio.get('estado').value;
    this.direccionEmpleado.codigoPostal = this.datosSocio.get('codigo_postal').value;
    this.direccionEmpleado.numExterior = this.datosSocio.get('numero_exterior').value;
    this.direccionEmpleado.numInterior = this.datosSocio.get('numero_interior').value;
    this.direccionEmpleado.referenciaDomicilio = this.datosSocio.get('referencia_domicilio').value;
    this.direccionEmpleado.calleAdelante = this.datosSocio.get('calle_adelante').value !== ''
      ? this.datosSocio.get('calle_adelante').value : 'XXXX';
    this.direccionEmpleado.calleAtras = this.datosSocio.get('calle_atras').value !== ''
      ? this.datosSocio.get('calle_atras').value : 'XXXX';
    this.direccionEmpleado.calleDerecha = this.datosSocio.get('calle_derecha').value !== ''
      ? this.datosSocio.get('calle_derecha').value : 'XXXX';
    this.direccionEmpleado.calleIzquierda = this.datosSocio.get('calle_izquierda').value !== ''
      ? this.datosSocio.get('calle_izquierda').value : 'XXXX';

    this.datosSocio.get('calle_adelante').setValue(this.datosSocio.get('calle_adelante').value !== ''
      ? this.datosSocio.get('calle_adelante').value : 'XXXX');
    this.datosSocio.get('calle_atras').setValue(this.datosSocio.get('calle_atras').value !== ''
      ? this.datosSocio.get('calle_atras').value : 'XXXX');
    this.datosSocio.get('calle_derecha').setValue(this.datosSocio.get('calle_derecha').value !== ''
      ? this.datosSocio.get('calle_derecha').value : 'XXXX');
    this.datosSocio.get('calle_izquierda').setValue(this.datosSocio.get('calle_izquierda').value !== ''
      ? this.datosSocio.get('calle_izquierda').value : 'XXXX');


    if (this.mapaGoogle === undefined || this.mapaGoogle.coordenadas === undefined) {
      this.openDialogMsg('Ocurrió un problema de conexión con el mapa.' +
        'Para poder continuar es necesario ubicar tu dirección en el mapa.', false);
      return null;
    }

    this.direccionEmpleado.latitud = this.mapaGoogle.coordenadas.lat.toFixed(10);
    this.direccionEmpleado.longitud = this.mapaGoogle.coordenadas.lng.toFixed(10);

    let direccion = [];
    direccion = [this.direccionEmpleado];
    let dirEmpleado = this.direccionEmpleado.calle + ' #';
    dirEmpleado += this.direccionEmpleado.numExterior + ', ';
    dirEmpleado += this.direccionEmpleado.colonia + ', ';
    dirEmpleado += this.direccionEmpleado.estado + ', ';
    dirEmpleado += this.direccionEmpleado.delegacionMunicipio + ', ';
    dirEmpleado += 'C.P. ' + this.direccionEmpleado.codigoPostal + '.';
    sessionStorage.setItem('direccionEmpleado', dirEmpleado);
    sessionStorage.setItem('telefonoEmpleado', telefono.telefono);

    this.homeService.iniciarPerfil();
    return new DatosSocioModel(this.responseInfoEmpleado.numeroEmpleado,
      this.datosSocio.get('correo').value, telefonoModel, direccion);
  }


  openDialogMapa(): void {
    const dialogRef = this.dialog.open(DialogValidacionComponent, {
      width: '500px',
      data: {
        message:
          '¿Estas seguro que la dirección ingresada y la ubicación en el mapa ' +
          'coincide con la del comprobante de domicilio?',
        btn1: 'Corregir',
        btn2: 'Si, continuar'
      },
      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        const guardarInformacion = this.guardarInformacion();
        if (guardarInformacion !== null) {
          this.datossocioService.guardarDatosSocio(
            this.numEmpleado, guardarInformacion).subscribe(respuesta => {
              this.siguientePagina();
            }, (err) => {
              this.reintentarConectar(err, 2);
            });
        }
      }
    });
  }

  // Reenviar a la página de 2.- Alta de documentos
  public continuar(isValid) {
    if (isValid && !this.validarCalles1()) {
      this.openDialogMapa();
    } else {
      this.openDialogMsg(UtileriaEnum.MENSAJE_CAMPOS_INCOMPLETO, false);
    }
  }

  private reintentarConectar(err, opcion): void {
    if (err.status === 500) {
      if (this.intentos <= 3) { // Opción para reintentar conectar con los servicios.
        this.intentos++;
        sessionStorage.setItem('intento', this.intentos.toString());
        this.dialog.closeAll();
        this.openDialogMsgSocios('Ocurrió un problema de conexión. Inténtalo nuevamente.', 'Reintentar', true, opcion);
      } else { // Termino el número de intentos.
        sessionStorage.removeItem('intento');
        this.dialog.closeAll();
        this.openDialogMsgSocios('Ocurrió un problema de conexión. Por favor inténtalo más tarde.', 'OK', false, opcion);
      }
    } else {
      this.dialog.closeAll();
      this.openDialogMsg(this.validacionErrors.getError(err), false);
    }
  }

  openDialogMsgSocios(msg: string, boton: string, reintento: boolean, opcion: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      if (reintento) {
        switch (opcion) {
          case 1: this.getDatosSocio(); break;
          case 2: this.continuar(true); break;
          case 3: this.obternerDatosPorCP(); break;
          case 4: this.getComboTipoDomicilioCliente(); break;
          case 5: this.getComboAntiguedadResidencias(); break;
          default: break;
        }
      } else {
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
    });
  }

  siguientePagina() {
    this.router.navigate(['/altadocumentos'], { skipLocationChange: false });
  }

  routeFunction(ruta: string) {
    this.router.navigate([ruta], { skipLocationChange: false });
  }

  openDialogMsg(msg: string, route: boolean): void {
    this.intentos++;
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
  }

  bloquearCampos(): void {
    this.datosSocio.disable();
  }

  desbloquearCampos(): void {
    this.datosSocio.enable();
  }

  mostrarBotonEditar(): void {
    this.showEditar = true;
  }

  mostrarBotonGuardar(): void {
    this.showEditar = false;
  }

  cancelar(): void {
    this.bloquearCampos();
    this.mostrarBotonEditar();
  }

  saveData(isvalid): void {
    if (isvalid && !this.validarCalles1()) {
      this.datossocioService.guardarDatosSocio(
        this.numEmpleado, this.guardarInformacion()).subscribe(respuesta => {
          this.bloquearCampos();
          this.mostrarBotonEditar();
          this.modifcarDireccionPerfil(this.datosSocio.get('telefono').value);
        }, (err) => {
          this.openDialogMsg(this.validacionErrors.getError(err), false);
        });
    } else {
      this.openDialogMsg(UtileriaEnum.MENSAJE_CAMPOS_INCOMPLETO, false);
    }

  }

  editar(): void {
    this.desbloquearCampos();
    this.mostrarBotonGuardar();
  }

  ngAfterViewInit(): void {
    const pantalla = this.router.url;
    if (pantalla !== '/datossocio') {
      setTimeout(() => {
        this.bloquearCampos();
      });
    }
  }

  openDialogCancelacionesMensaje(): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        message: '¿Estás seguro que deseas salir? No se guardará el avance.',
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
    });
  }

  modifcarDireccionPerfil(telefono) {
    let dirEmpleado = this.direccionEmpleado.calle + ' #';
    dirEmpleado += this.direccionEmpleado.numExterior + ', ';
    dirEmpleado += this.direccionEmpleado.colonia + ', ';
    dirEmpleado += this.direccionEmpleado.estado + ', ';
    dirEmpleado += this.direccionEmpleado.delegacionMunicipio + ', ';
    dirEmpleado += 'C.P. ' + this.direccionEmpleado.codigoPostal + '.';
    sessionStorage.setItem('telefonoEmpleado', telefono);
    sessionStorage.setItem('direccionEmpleado', dirEmpleado);
  }
}
