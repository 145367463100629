
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../../shared/components/spinnner/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewMsg } from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg'
import { ApiAutorizacionesService } from 'src/app/core/services/credito/api.autorizaciones/api.autorizaciones.service';
import { DialogCancelacionComponent } from 'src/app/shared/components/modales/modalMaterialCancelaciones/dialog-overview-msg1';
import { DialogMensajeComponent } from 'src/app/shared/components/modales/modalMaterialCancelaciones.1/dialog-overview-msg1';
import { ApiAdelantoService } from 'src/app/core/services/credito/api.adelanto/api.adelanto';
import { ApiPrestamosService, UserService } from 'src/app/core';
import { DialogReintentarComponent } from 'src/app/shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { ValidacionErrors } from 'src/app/core/services/utileria/validacion.error';

@Component({
  selector: 'app-autoriza-solicitudfam',
  template: `
  <h2 class="title">Autoriza tu solicitud.</h2>
  <p class="text-center">Autoriza con tu Firma Azteca o Token App BAZ:</p>
  <form class="content_form" [formGroup]="formToken" (ngSubmit)="continuar(formToken.valid)" autocomplete="off">
      <div class="col col-xl-7 col-lg-7 col-md-7 col-sm-12 firma">
          <mat-form-field class="example-full-width">
              <input class="tok" name="firmaAztecaToken" [formControl]="formToken.controls.firmaAztecaToken"
              type="password" placeholder="Firma Azteca o Token App BAZ" matInput numbersOnly maxlength="6" minlength="6">
          </mat-form-field>
          <div class="error-firmaazteca" *ngIf="errorToken">
          Credenciales inválidas
          </div>
      </div>
      <div class="col pad btns_foot">
          <button class="btn cancelar" type="button" (click)="openDialogCancelacionesMensaje()"
            routerLinkActive="router-link-active">Cancelar</button>
          <button class="btn all" type="submit">Continuar</button>
      </div>
  </form>
  `,
  styles: ['html { display: none; }']
})
export class AutorizaSolicitudFAMComponent implements OnInit, OnDestroy {

  numEmpleado: string;
  idProducto: string;
  idCotizador: string;
  formToken: FormGroup;
  validaFAMToken: boolean;
  intentos: number;
  intentos_api: number;
  errorToken: boolean;
  token: number;
  valida: ValidacionErrors;
  private INTENTOS_MAX = 3;
  private INTENTOS_API = 2;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private spinnerSrv: SpinnerService,
    private dialog: MatDialog,
    private apiAutorizaciones: ApiAutorizacionesService,
    private apiAdelanto: ApiAdelantoService,
    private apiPrestamo: ApiPrestamosService,
    private router: Router) {
      this.valida = new ValidacionErrors();
  }

  ngOnInit(): void {
    this.intentos = 0;
    this.intentos_api = 0;
    this.errorToken = false;
    this.formToken = this.formBuilder.group({
      firmaAztecaToken: ['', Validators.required]
    });
    if (window.sessionStorage) {
      const idProducto = window.sessionStorage ? sessionStorage.getItem('producto_id') : '';
      if (idProducto !== null && idProducto !== undefined && idProducto.trim() !== '') {
        this.infoSessionStorage();
      } else {
        setTimeout(() => {
          this.openDialogMsg('Por favor selecciona un producto para continuar', true);
        });

      }
    }

  }

  infoSessionStorage(): void {
    this.numEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    this.idProducto = window.sessionStorage ? sessionStorage.getItem('producto_id') : '';
    this.idCotizador = window.sessionStorage ? sessionStorage.getItem('idCotizador') : '';
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('montoCredito');
  }
  continuar(valid: boolean): void {
    this.errorToken = false;
    this.token = (this.formToken.controls['firmaAztecaToken'].value);
    if (valid && this.intentos === this.INTENTOS_MAX) {
      this.openDialogMsg('Por favor, verifica tus credenciales.', true);
    } else if (valid) {
      this.infoSessionStorage();
      this.consultaApiAutorizaciones();
    }
  }

  consultaApiAutorizaciones(): void {
    this.apiAutorizaciones.postAutorizaciones(this.numEmpleado, this.token).subscribe(data => {
      this.intentos_api = 0;
      if (data.resultado.codigo === '0') {
        this.postCredito();
      } else if (data.resultado.codigo === '-1' || data.resultado.codigo === '-15' || data.resultado.codigo === '-8' || data.resultado.codigo === '-9') {
        this.intentos += 1;
        this.errorToken = true;
      }
    }, error => {
      this.errorAPIConexion(error, 1);
    });
  }

  postCredito(): void {
    switch (this.idProducto) {
      case '1':
        this.apiAdelanto.postAdelanto(this.numEmpleado, this.idCotizador).subscribe(success => {
          this.spinnerSrv.abrirSpinner('Enviando documentos a Mesa de Crédito para su validación.');
          window.sessionStorage ?
            sessionStorage.setItem('folioCredito', success.resultado.folio) :
            sessionStorage.setItem('folioCredito', '');
          this.routeFunction('/resumen');
        }, error => {
          this.errorAPIConexion(error, 2);
        });
        break;
      case '2':
        this.apiPrestamo.postPrestamo(this.numEmpleado, this.idCotizador,this.idProducto).subscribe(success => {
          this.spinnerSrv.abrirSpinner('Enviando documentos a Mesa de Crédito para su validación.');
          window.sessionStorage ?
            sessionStorage.setItem('folioCredito', success.resultado.folio) :
            sessionStorage.setItem('folioCredito', '');
          this.routeFunction('/resumen');
        }, error => {
          console.error(error);
          this.errorAPIConexion(error, 2);
        });
        break;
    }
  }

  errorAPIConexion(error, option: number): void {
    if (error.status === 500 && this.intentos_api >= this.INTENTOS_API) {
      this.intentos_api = 0;
      this.dialog.closeAll();
      this.openDialogMsg('Ocurrio un error al conectar con los servicios. Por favor inténtalo más tarde', true);
    } else {
      if (error.status === 500 && this.intentos_api < this.INTENTOS_API) {
        this.dialog.closeAll();
        this.intentos_api++;
        this.openDialogReintentar('Ocurrio un error al realizar la consulta', 'Reintentar', option);
      } else {
        this.openDialogMsg(this.valida.getError(error), false);
      }
    }
  }

  openDialogReintentar(msg: string, boton: string, option: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      switch (option) {
        case 1: this.consultaApiAutorizaciones(); break;
        case 2: this.postCredito(); break;
      }
    });
  }

  openDialogMsg(msg: string, route: boolean) {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route) {
        this.routeFunction('/home');
      }
    });
  }

  routeFunction(ruta: String) {
    this.router.navigate([ruta], { skipLocationChange: false });
  }

  openDialogCancelaciones(): void {
    const dialogRef = this.dialog.open(DialogCancelacionComponent, {
      width: '500px',
      data: {
        message: '',
        opciones: true,
        disableClose: true,
        rutaDestino: '/home'
      },
      autoFocus: false
    });

  }

  openDialogCancelacionesMensaje(): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        message: '¿Estás seguro que deseas salir? No se guardará el avance.',
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openDialogCancelaciones();
      }
    });
  }
}
