import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConsentimientoModel } from './consentimiento.model';
import { HeaderService } from '../header.options';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewMsg } from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { environment } from '../../../../../environments/environment';
import { ValidacionErrors } from '../../utileria/validacion.error';

@Injectable({ providedIn: 'root' })
export class ApiConsentimientoService {
    constructor(
        private httpClient: HttpClient,
        private validacionError: ValidacionErrors,
        private header: HeaderService,
        private dialog: MatDialog
    ) {
    }

    postConsentimiento(consetimientoModel: ConsentimientoModel): Observable<any> {
        return this.httpClient.post(environment.api_url_consentimiento, consetimientoModel,
            { headers: this.header.postHeaderOption(false) })
            .pipe(
                catchError(e => {
                throw e;
                })
            );
    }


    openDialogMsg(msg: string): void {
        this.dialog.open(DialogOverviewMsg, {
            width: '500px',
            data: {
                message: msg,
                disableClose: true,
                autoFocus: false
            }
        });
    }
}
