import { TelefonoModel } from './telefono.model';

export class ReferenciaModel {

    idReferencia: number;
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    correoElectronico: string;
    idParentesco: number;
    parentesco: string;
    otroParentesco: string;
    telefonos: TelefonoModel[];
}

export class RequestReferenciasModel {
    numeroEmpleado: string;
    referencias: ReferenciaModel[];
}
