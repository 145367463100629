import { Component, Host, OnInit } from '@angular/core';
import { BodyComponent } from 'src/app/shared';

@Component({
  selector: 'app-perfilsocio',
  template: `
  <mat-tab-group>
    <mat-tab label='Datos personales'>
      <ng-template matTabContent>
        <div>
          <app-datos-socio></app-datos-socio>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label='Referencias'>
      <ng-template matTabContent>
      <div>
        <app-referencias-familiares></app-referencias-familiares>
      </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  `
})

export class PerfilsocioComponent implements OnInit {

  body: BodyComponent;
  numeroEmpleado: string;
  email: string;
  tipoEmpleado: string;

  constructor(
     body: BodyComponent) {
      this.body = body;
    }

  ngOnInit() {
    this.body.setImagenAside('');
  }

}
