<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/FOLLETO_TPR.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/Guia_TPr.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
    <a class="faqs" href="../../../../../../assets/docs/home/Preguntas_Frecuentes_TPr.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">¡TPremia es tan leal como tú!</h1>
<p class="textoverde"><strong>¿Qué es?</strong></p>
<p>Es el <strong>programa de recompensas</strong>, que te reconoce como <strong>Colaborador</strong> y al ser cliente de <strong>Banco Azteca, Elektra, Italika y Presta Prenda</strong>, te otorga, Beneficios y descuentos en comercios a nivel nacional</p>
<p class="textoverde"><strong>¿Para quién es?</strong></p>
<ul class="contenido">
    <li>Personas que laboren en GS</li>
    <li>Que activen su cuenta TPremia </li>
    <li>Que utilicen o contraten alguno de los productos participantes</li>
</ul>
<p class="textoverde"><strong>¿Qué beneficios exclusivos te ofrece?</strong></p>
<p>Podrás canjear una recompensa <sup>1</sup> cada 1,000 puntos T</p>
<p><strong>Acumulas puntos T al realizar: </strong> </p>

<div  class="container" >
    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-10 col-xl-8 ">
           
            <img  style ="width: 100%;" src="../../../../assets/img/tablaTPR.png" />
            
          </div>

    </div>
</div>

<div class="subindices">
    <p><strong>Nota:</strong> Los productos de crédito para Colaboradores (Adelanto de Efectivo, Crédito Personal GS, Venta Empleado y Tarjeta Oro para Colaboradores), por el momento no acumulan puntos T</p>
</div>
<br>

<div class="subindices">
    <p><sup>1</sup>Más información en <a href="https://tpremia.com.mx/recompensas">Recompensas</a></p>
    <p><sup>2</sup>Solo aplican para Colaboradores con los productos disponibles, consulta <a href="https://tpremia.com.mx/terminos-y-condiciones">Términos y Condiciones </a></p>
</div>
<div class="row">
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
      </div>
      <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
      </div>
</div>
<hr class="prodBottom">
<p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
<div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
    href="mailto:tpremia@bancoazteca.com.mx">tpremia@bancoazteca.com.mx</a></div><br><br><br>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>